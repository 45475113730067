import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './PropertySize.module.scss';

const PropertySize = ({ classList = {}, onClickSizes, size, selected }) => {
  return (
    <li onClick={() => onClickSizes(size)} className={classNames(styles.propertySize, { [styles.selected]: selected })}>
      <div className={classNames(styles.propertySize, classList.root, { [styles.selected]: selected })}>{size}</div>
    </li>
  );
};

PropertySize.propTypes = {
  size: PropTypes.string,
  selected: PropTypes.bool,
  onClickSizes: PropTypes.func,
};

PropertySize.defaultProps = {
  selected: false,
};

export default PropertySize;

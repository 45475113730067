import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./ProductDetailsCard.module.scss";

import slugify from "../helpers/slugify";

import { ReactComponent as GoToDetailsIcon } from "../images/icons/expand.svg";
import { ReactComponent as ShareIcon } from "../images/icons/share.svg";
import { ReactComponent as CloseIcon } from "../images/icons/close.svg";

import Gallery from "../Gallery/Gallery";
import Share from "../Share/Share";

export const ExtendedPDCLayout = ({
  viewState,
  classList,
  product,
  galleryImages,
  onClose,
  productDetails,
  shops,
  PDCref,
  isAllShopsBtnShown,
}) => {
  const goToProduct = () => {
    window.open(
      `${process.env.REACT_APP_SITE_VERSION}/product/${slugify(name)}/${id}/`,
      "_blank",
      "noopener,noreferrer"
    );
  };
  const [openShare, setOpenShare] = useState(false);
  const { name, id } = product;
  return (
    <div
      className={classnames(
        styles.root,
        classList.root,
        {
          [styles.root_opened]: viewState.isOpened,
          [styles.allShopsBtnShown]: isAllShopsBtnShown,
        },
        "col-12-xs"
      )}
      ref={PDCref}
    >
      <header className={styles.header}>
        <h2 className={styles.headerH2}>{name}</h2>
        <div className={styles.actionBtns}>
          {!process.env.REACT_APP_LINK_ON_CARDS && (
            <FormattedMessage id="product.moreInfo" defaultMessage="More info">
              {(label) => (
                <button
                  aria-label={label}
                  className={styles.goToDetailsBtn}
                  onClick={goToProduct}
                >
                  <GoToDetailsIcon />
                </button>
              )}
            </FormattedMessage>
          )}
          {process.env.REACT_APP_LINK_ON_CARDS && (
            <FormattedMessage id="product.moreInfo" defaultMessage="More info">
              {(label) => (
                <a
                  aria-label={label}
                  className={styles.goToDetailsBtn}
                  href={`${
                    process.env.REACT_APP_SITE_VERSION
                  }/product/${slugify(name)}/${id}/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <GoToDetailsIcon />
                </a>
              )}
            </FormattedMessage>
          )}
          <FormattedMessage id="common.share" defaultMessage="Share">
            {(label) => (
              <button
                aria-label={label}
                className={classnames(styles.shareBtn, {
                  [styles.shareButtonSelected]: openShare,
                })}
                onClick={() => setOpenShare(!openShare)}
              >
                <ShareIcon />
                {openShare && (
                  <div className={styles.share}>
                    <Share
                      url={`${
                        process.env.REACT_APP_SITE_VERSION
                      }/product/${slugify(name)}/${id}/`}
                      withEmail
                    />
                  </div>
                )}
              </button>
            )}
          </FormattedMessage>
          <FormattedMessage id="common.close" defaultMessage="Close">
            {(label) => (
              <button
                aria-label={label}
                className={styles.closeBtn}
                onClick={onClose}
              >
                <CloseIcon />
              </button>
            )}
          </FormattedMessage>
        </div>
      </header>
      <section
        className={styles.galleryAndDetails}
        onClick={() => setOpenShare(false)}
      >
        <div className={styles.gallery}>
          <Gallery images={galleryImages.slice(0, 4)} />
        </div>
        <div className={styles.details}>{productDetails}</div>
      </section>
      {shops}
    </div>
  );
};

ExtendedPDCLayout.propTypes = {
  viewState: PropTypes.object.isRequired,
  classList: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  galleryImages: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  productDetails: PropTypes.element.isRequired,
  wishlistButton: PropTypes.element.isRequired,
  isAllShopsBtnShown: PropTypes.bool.isRequired,
  shops: PropTypes.element.isRequired,
};

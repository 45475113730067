import useSearchApi from '../helpers/useSearchApi';
import useContentApi from '../helpers/useContentApi';
import { useState, useEffect } from 'react';

const productDetailsInitial = {
  shopInfoData: null,
  shopsDataBody: null,
};

function getSearchApiOptions(selectedProductObject) {
  if (!selectedProductObject) return selectedProductObject;
  const requested = ['brand', 'campaignID', 'EAN', 'family_code', 'GTIN', 'id', 'ISBN', 'SKU'];
  return requested.reduce((options, prop) => {
    return { ...options, [prop]: selectedProductObject[prop] };
  }, {});
}

export const useProductDetails = (selectedProductObject = {}) => {
  const [product, setProduct] = useState('');
  const [dataReady, setDataReady] = useState(false);
  const [productDetails, setProductDetails] = useState(productDetailsInitial);

  const productShopsHeaders = {};
  const searchApiOptionsData = getSearchApiOptions(selectedProductObject);
  const isProductSelected = !!selectedProductObject;
  const { data: shopsDataBody, loading: dataBodyLoading } = useSearchApi('/product-shops', {
    headers: productShopsHeaders,
    data: searchApiOptionsData,
    method: 'POST',
    shouldRun: isProductSelected,
  });

  const shopid = selectedProductObject?.shop_id;
  const { data: shopInfoData, loading: infoDataLoading } = useContentApi(`/productDetailCardShop/${shopid}`, {
    shouldRun: isProductSelected,
  });

  useEffect(() => {
    if (!isProductSelected) {
      setProduct(null);
      setDataReady(false);
      setProductDetails(productDetailsInitial);
    }
  }, [isProductSelected]);

  useEffect(() => {
    const requestedProduct = selectedProductObject?.id || '';
    const productChanged = requestedProduct !== product;
    const dataLoaded = !infoDataLoading && !dataBodyLoading && !!(shopsDataBody || shopInfoData);

    if (productChanged && dataLoaded) {
      setProduct(requestedProduct);
      setDataReady(true);
      setProductDetails({ shopInfoData, shopsDataBody });
    } else {
      setDataReady(false);
      setProductDetails(productDetailsInitial);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataBodyLoading, infoDataLoading]);

  return { dataReady, ...productDetails };
};

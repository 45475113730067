import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './PropertyColor.module.scss';

const PropertyColor = ({ color, selected, onClickColor, onMouseEnterColor, onMouseLeaveColor }) => {
  const handleClickColor = (color) => {
    onClickColor(color);
  };

  const handleMouseEnter = (color) => {
    onMouseEnterColor(color);
  };

  const handleMouseLeave = (color) => {
    onMouseLeaveColor(color);
  };

  return (
    <li
      onClick={() => handleClickColor(color)}
      className={classNames(styles.propertyColor, { [styles.selected]: selected })}
      onMouseEnter={() => handleMouseEnter(color)}
      onMouseLeave={() => handleMouseLeave(color)}
    >
      <div className={classNames({ [styles.selected]: selected })}>
        <img alt="color" loading="lazy" src={color.path} title="color" width="auto" height="38" />
      </div>
    </li>
  );
};

PropertyColor.propTypes = {
  color: PropTypes.object,
  selected: PropTypes.bool,
  onClickColor: PropTypes.func,
  onMouseEnterColor: PropTypes.func,
  onMouseLeaveColor: PropTypes.func,
};

PropertyColor.defaultProps = {
  selected: false,
};

export default PropertyColor;

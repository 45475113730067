import React, { useEffect, useReducer, useState } from "react";
import classnames from "classnames";

import Product from "../Product/Product";
import ProductDetailsCard from "../ProductDetailsCard/ProductDetailsCard";
import {
  animateScroll,
  stopCardAnimation,
} from "../ProductDetailsCard/cardAnimation";

import getShopsList from "../helpers/getShopsList";
import getCDNImage from "../helpers/getCDNImage";
import { propsComparison } from "../helpers/propsComparison";
import { useProductDetails } from "./useProductDetails";
import { getNodePageY } from "../helpers/getNodePageY";

import styles from "./ProductsList.module.scss";

const getProductFromSearchData = (searchData, productHash) => {
  const searchDataReady = searchData && productHash;
  const searchDataItemIndex =
    searchDataReady &&
    searchData.hits.hits.findIndex((item) => item._id === productHash);
  const selectedProductIndex =
    searchDataItemIndex === -1 ? null : searchDataItemIndex;
  const searchDataItem = searchData.hits.hits[selectedProductIndex];

  if (!searchDataItem) return null;

  const source = searchDataItem?._source;
  return {
    ...source,
    id: searchDataItem._id,
    itemIndex: selectedProductIndex,
    EAN: source.offers[0].EAN,
    originalPrice: source.price_original || 0,
    shopId: source.shop_id.toString(),
    url: source.offers[0].url,
    shops: getShopsList([source]),
    productImages: source.images,
  };
};

const initialViewState = {
  isOpening: false, //open card with animation
  isClosing: false, //close card with animation
  isOpened: false, //render card already opened
};

//Card actions. Used in productsListReducer and in ProductsList useEffect hook
const OPEN_CARD_INSTANTLY = "OPEN_CARD_INSTANTLY";
const OPEN_CARD_SMOOTHLY = "OPEN_CARD_SMOOTHLY";
const SWITCH_CARDS_INSTANTLY = "SWITCH_CARDS_INSTANTLY";
const SWITCH_CARDS_SMOOTHLY = "SWITCH_CARDS_SMOOTHLY";
const CLOSE_CARD_SMOOTHLY = "CLOSE_CARD_SMOOTHLY";
const CARD_RESET = "CARD_RESET";

const initialPLState = {
  apiData: { shopInfoData: null, shopsDataBody: null },
  isSameRowClicked: false,
  lastClickedRowCoords: { offsetYTop: null, offsetYBottom: null },
  onClosed: () => {},
  selectedProduct: "",
  viewState: initialViewState,
};

const PLReducer = (state, action) => {
  switch (action.type) {
    case OPEN_CARD_SMOOTHLY:
      return {
        ...state,
        apiData: action.apiData,
        isSameRowClicked: action.isSameRowClicked,
        lastClickedRowCoords: action.lastClickedRowCoords,
        onClosed: action.onClosed,
        selectedProduct: action.selectedProduct,
        viewState: action.viewState,
      };
    case SWITCH_CARDS_INSTANTLY:
      return {
        ...state,
        apiData: action.apiData,
        isSameRowClicked: action.isSameRowClicked,
        selectedProduct: action.selectedProduct,
        viewState: action.viewState,
      };
    case SWITCH_CARDS_SMOOTHLY:
      return {
        ...state,
        onClosed: action.onClosed,
        viewState: action.viewState,
      };
    case CLOSE_CARD_SMOOTHLY:
      return {
        ...state,
        onClosed: action.onClosed,
        viewState: action.viewState,
      };
    case OPEN_CARD_INSTANTLY:
      return {
        ...state,
        selectedProduct: action.selectedProduct,
        apiData: action.apiData,
        viewState: action.viewState,
      };
    default:
      return initialPLState;
  }
};

const ProductsList = React.memo(
  ({ classList, viewOption, isTablet, searchData }) => {
    const products =
      searchData &&
      searchData.hits.hits.length > 0 &&
      searchData.hits.hits.map((hit) => ({ ...hit._source, id: hit._id }));

    const [productTarget, setProductTarget] = useState(null);
    const [onReadyAction, setOnReadyAction] = useState(null);
    const [selectedProductHash, setSelectedProductHash] = useState(null);
    const [state, dispatch] = useReducer(PLReducer, initialPLState);

    const isSelectedProductPresent = !!products.find(
      (item) => item.id === selectedProductHash
    );

    const createDetailsCard = (product, apiData, viewState) => (
      <ProductDetailsCard
        key={`productDetailsCard_${product.id}`}
        classList={{ root: styles.productDetailsCard }}
        isTablet={isTablet}
        onClose={isTablet ? closeCardSmoothly : closeCard}
        onClosed={state.onClosed}
        product={product}
        viewOption={viewOption}
        {...apiData}
        viewState={viewState}
      />
    );

    const isClickedOnTheSameRow = ({ pageY }) => {
      //bottom and top coordinates are the offsetY from the document top
      const { offsetYBottom, offsetYTop } = state.lastClickedRowCoords;
      return pageY <= offsetYBottom && pageY >= offsetYTop;
    };

    function closeCard() {
      setSelectedProductHash("");
      dispatch({ type: CARD_RESET });
    }

    const openCardInstantly = () => {
      const selectedProductNode = document.getElementById(selectedProductHash);
      animateScroll(selectedProductNode);
      dispatch({
        type: OPEN_CARD_INSTANTLY,
        selectedProduct: selectedProductHash,
        apiData,
        viewState: { ...initialViewState, isOpened: true },
      });
    };

    //Opens new card with animation
    const openCardSmoothly = (productCardNode, selectedProduct, apiData) => {
      const offsetTop = getNodePageY(productCardNode);
      const { height } = productCardNode.getBoundingClientRect();

      dispatch({
        type: OPEN_CARD_SMOOTHLY,
        apiData,
        isSameRowClicked: false,
        lastClickedRowCoords: {
          offsetYTop: offsetTop,
          offsetYBottom: offsetTop + height,
        },
        onClosed: closeCard,
        selectedProduct,
        viewState: { ...initialViewState, isOpening: true },
      });
    };

    //Opens new card without animation when it's loaded
    const switchOpenedCardsInstantly = (
      productCardNode,
      selectedProductHash,
      apiData
    ) => {
      dispatch({
        type: SWITCH_CARDS_INSTANTLY,
        apiData,
        isSameRowClicked: true,
        selectedProductHash,
        viewState: { ...initialViewState, isOpened: true },
      });
    };
    //Opens new and closes previous with animation
    const switchOpenedCardsSmoothly = (
      productNode,
      selectedProductHash,
      apiData
    ) => {
      dispatch({
        type: SWITCH_CARDS_SMOOTHLY,
        viewState: { ...initialViewState, isClosing: true },
        onClosed: () => {
          animateScroll(productNode);
          openCardSmoothly(productNode, selectedProductHash, apiData);
        },
      });
    };

    //Closes single card with animation
    const closeCardSmoothly = (productCardNode) => {
      dispatch({
        type: CLOSE_CARD_SMOOTHLY,
        viewState: { ...initialViewState, isClosing: true },
        onClosed: closeCard,
      });
    };

    const onProductClick = (productId) => (e) => {
      const sameRow = isClickedOnTheSameRow(e);
      stopCardAnimation();
      const currentTarget = e.currentTarget;
      const sameProduct = productId === selectedProductHash;
      setProductTarget(currentTarget);
      if (!sameProduct) {
        setSelectedProductHash(productId);
      }
      if (sameProduct) {
        closeCardSmoothly(currentTarget);
      } else if (sameRow) {
        isTablet && animateScroll(currentTarget);
        setOnReadyAction(SWITCH_CARDS_INSTANTLY);
      } else if (!selectedProductHash || !isSelectedProductPresent) {
        isTablet && animateScroll(currentTarget);
        setOnReadyAction(OPEN_CARD_SMOOTHLY);
      } else {
        setOnReadyAction(SWITCH_CARDS_SMOOTHLY);
      }
    };
    //Render
    const productsListView = products.map((product) => {
      const productImages = product.images.slice(0, 4).map((image) => ({
        original: getCDNImage(image),
        thumbnail: getCDNImage(image),
        originalAlt: product.name,
        thumbnailAlt: product.name + " - thumbnail",
        originalTitle: product.name,
        thumbnailTitle: product.name + " - thumbnail",
      }));
      return (
        <Product
          brand={product.brand}
          classList={{ root: styles.productCard }}
          currency={product.currency}
          shortDesc={product.shortDesc}
          EAN={product.offers[0].EAN}
          price={product.price}
          originalPrice={product.price_original || product.price}
          key={product.id}
          id={product.id}
          image={getCDNImage(product.thumbs[0])}
          images={productImages}
          isSelected={selectedProductHash && product.id === selectedProductHash}
          name={product.name}
          onClick={onProductClick(product.id)}
          shop={{ name: product.campaign }}
          SKU={product.SKU}
          url={product.offers[0].url}
          viewOption={viewOption}
        />
      );
    });
    const currentProductObject = getProductFromSearchData(
      searchData,
      selectedProductHash
    );
    const hashProductObject = getProductFromSearchData(
      searchData,
      selectedProductHash
    );
    const { dataReady, shopInfoData, shopsDataBody } = useProductDetails(
      hashProductObject
    );
    const currentDataReady = !!(
      (state.apiData.shopInfoData || state.apiData.shopsDataBody) &&
      currentProductObject
    );
    const apiData = { shopInfoData, shopsDataBody };
    useEffect(() => {
      if (dataReady) {
        switch (onReadyAction) {
          case OPEN_CARD_SMOOTHLY:
            openCardSmoothly(productTarget, selectedProductHash, apiData);
            break;
          case SWITCH_CARDS_INSTANTLY:
            switchOpenedCardsInstantly(
              productTarget,
              selectedProductHash,
              apiData
            );
            break;
          case SWITCH_CARDS_SMOOTHLY:
            switchOpenedCardsSmoothly(
              productTarget,
              selectedProductHash,
              apiData
            );
            break;
          default:
            selectedProductHash && openCardInstantly();
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataReady, selectedProductHash]);

    const detailsCard = currentDataReady
      ? createDetailsCard(currentProductObject, state.apiData, state.viewState)
      : null;
    const PLIClassName = classnames(styles.root, {
      [styles.viewOptionColumns]: viewOption === "column",
      [styles.viewOptionTiles]: viewOption === "tiles",
    });

    return (
      <ProductsListItems
        selectedProductIndex={currentProductObject?.itemIndex}
        productsList={productsListView}
        detailsCard={detailsCard}
        classList={PLIClassName}
      />
    );
  },
  propsComparison
);

const viewStatesAreEqual = (prevViewState, nextViewState) => {
  return (
    prevViewState?.isOpened === nextViewState?.isOpened &&
    prevViewState?.isOpening === nextViewState?.isOpening &&
    prevViewState?.isClosing === nextViewState?.isClosing
  );
};
const pliPropsAreEqual = (prevProps, nextProps) => {
  return (
    prevProps.selectedProductIndex === nextProps.selectedProductIndex &&
    prevProps.productsList?.length === nextProps.productsList?.length &&
    propsComparison(prevProps.productsList[0], nextProps.productsList[0]) &&
    prevProps.offline === nextProps.offline &&
    viewStatesAreEqual(
      prevProps.detailsCard?.props.viewState,
      nextProps.detailsCard?.props.viewState
    )
  );
};

const ProductsListItems = React.memo(
  ({ selectedProductIndex, productsList, detailsCard, classList }) => {
    const itemsList = detailsCard
      ? [
          ...productsList.slice(0, selectedProductIndex + 1),
          detailsCard,
          ...productsList.slice(selectedProductIndex + 1),
        ]
      : productsList;

    return (
      <section className={classList}>
        {itemsList.map((item) => {
          return item;
        })}
      </section>
    );
  },
  pliPropsAreEqual
);

ProductsListItems.displayName = "ProductsListItems";
ProductsList.displayName = "ProductsList";

export default ProductsList;

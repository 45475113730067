import { combineReducers, configureStore } from "@reduxjs/toolkit";

import logger from "redux-logger";

import { searchPayloadReducer } from "./reducers/searchPayloadReducer";
import { configReducer } from "./reducers/configReducer";
import { categoriesReducer } from "./reducers/categoriesReducer";

const reducer = combineReducers({
  searchPayload: searchPayloadReducer,
  categories: categoriesReducer,
  config: configReducer,
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => process.env.NODE_ENV !== "production" ? getDefaultMiddleware().concat(logger) : getDefaultMiddleware(),
  devTools: process.env.NODE_ENV !== "production",
});

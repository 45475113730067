import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";

import useContentApi from "./helpers/useContentApi";
import useSearchApi from "./helpers/useSearchApi";

import { mainCategories, esCategories } from "./reducers/categoriesReducer";
import { defaults } from "./reducers/configReducer";

import SearchBar from "./SearchBar/SearchBar";
import SearchResults from "./SearchResults/SearchResults";

import styles from "./App.module.scss";

function App() {
  const [showProducts, setShowProducts] = useState(false);
  const dispatch = useDispatch();

  const { data: mainCats } = useContentApi("/shops/main-cats");
  const { data: init } = useContentApi("/init");
  const { data: esCats } = useSearchApi("/categories-routes", {
    withCredentials: false,
  });

  if (esCats) dispatch(esCategories(esCats.category.buckets));
  if (mainCats) dispatch(mainCategories(mainCats));
  if (init) dispatch(defaults(init));

  if (!init || !esCats || !mainCats) {
    return null;
  }

  const rootElm = document.querySelector("body");

  const Modal = () => {
    return ReactDOM.createPortal(
      <>
        <div id="siab-widgetOverlay">
          <div className={styles.siabOverlay}>
            <div className={styles.siabModal}>
              <div
                className={styles.siabClose}
                onClick={() => setShowProducts(false)}
              >
                x
              </div>
              <header className={styles.siabHeader}>
                <img src={init.defaults.theme.logos.main_logo} />
                <SearchBar modal="true" />
              </header>
              <SearchResults />
            </div>
          </div>
        </div>
      </>,
      rootElm
    );
  };

  return (
    <div className={styles.siabApp}>
      <div style={{ maxWidth: "1560px" }}>
        <div>
          <SearchBar handleShowProducts={setShowProducts} />
        </div>
        {showProducts && <Modal />}
      </div>
    </div>
  );
}

export default App;

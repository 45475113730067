import classnames from "classnames";
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import styles from "./Drawer.module.scss";

export const DRAWER_DIRECTION_BTT = "BottomToTop";
export const DRAWER_DIRECTION_LTR = "LeftToRight";
export const DRAWER_DIRECTION_RTL = "RightToLeft";
export const DRAWER_DIRECTION_TTB = "TopToBottom";

const DRAWER_BASE_ZINDEX = 300;

const Drawer = React.memo(
  ({
    classList = {},
    children,
    direction = DRAWER_DIRECTION_LTR,
    onOverlayClick,
    isNested = false,
    withOverlay = true,
  }) => {
    const [shouldBeOpen, setShouldBeOpen] = useState(false);

    useEffect(() => {
      setShouldBeOpen(true);
      document.body.style.overflow = "hidden";
      document.body.style.touchAction = "none";

      return () => {
        setShouldBeOpen(false);
        document.body.style.overflow = "initial";
        document.body.style.touchAction = "auto";
      };
    }, []);
    const transitionDirection = shouldBeOpen ? "Out" : "In";

    const [currentOverlay, setCurrentOverlay] = useState(
      `transition${transitionDirection}Fx`
    );
    const [currentDrawer, setCurrentDrawer] = useState(
      `transition${transitionDirection}Fx`
    );

    const rootElm = document.querySelector("body");

    const handleOverlayTransitionEnd = () => {
      const transitionDirection = shouldBeOpen ? "Out" : "In";
      setCurrentOverlay(`transition${transitionDirection}Fx`);
    };

    const handleDrawerTransitionEnd = () => {
      const transitionDirection = shouldBeOpen ? "Out" : "In";
      setCurrentDrawer(`transition${transitionDirection}Fx`);
    };

    const handleOverlayClick = () => {
      if (typeof onOverlayClick === "function") {
        onOverlayClick();
      }
    };

    const overlayClasses = classnames(styles.root, styles[currentOverlay], {
      [styles.open]: shouldBeOpen,
    });

    const drawerClasses = classnames(
      styles.drawer,
      styles[currentDrawer],
      styles[`direction${direction}`],
      classList.root,
      {
        [styles.open]: shouldBeOpen,
      }
    );

    const overlayStyles = {
      zIndex: isNested ? DRAWER_BASE_ZINDEX + 5 : DRAWER_BASE_ZINDEX,
    };

    const drawerStyles = {
      zIndex: isNested ? Drawer.zIndex + 5 : Drawer.zIndex,
    };

    return ReactDOM.createPortal(
      <>
        {withOverlay && (
          <div
            className={overlayClasses}
            data-testid="drawerOverlay"
            onTransitionEnd={() => handleOverlayTransitionEnd()}
            style={overlayStyles}
            onClick={handleOverlayClick}
          />
        )}
        <div
          className={drawerClasses}
          onTransitionEnd={() => handleDrawerTransitionEnd()}
          style={drawerStyles}
        >
          {shouldBeOpen && children}
        </div>
      </>,
      rootElm
    );
  }
);

Drawer.displayName = "Drawer";
Drawer.zIndex = DRAWER_BASE_ZINDEX;
Drawer.propTypes = {
  children: PropTypes.node,
  direction: PropTypes.oneOf([
    DRAWER_DIRECTION_BTT,
    DRAWER_DIRECTION_LTR,
    DRAWER_DIRECTION_RTL,
    DRAWER_DIRECTION_TTB,
  ]),
  onOverlayClick: PropTypes.func,
  trigger: PropTypes.string,
  withOverlay: PropTypes.bool,
};

export default Drawer;

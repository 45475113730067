import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

const categoryAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt),
});

const slice = createSlice({
  name: "categories",
  initialState: categoryAdapter.getInitialState({
    mainCategories: [],
    esCategories: [],
  }),
  reducers: {
    mainCategories: (state, action) => {
      state.mainCategories = action.payload.reduce((output, cat) => {
        output[cat.slug] = {
          img_blue: cat.img_blue,
          img: cat.img,
          name: cat.name,
          slug: cat.slug,
        };

        return output;
      }, {});
    },
    esCategories: (state, action) => {
      state.esCategories = action.payload.reduce((output, cat) => {
        output[cat.slug] = {
          key: cat.key,
          name: cat.key,
          slug: cat.slug,
          subCategories: cat.subcategory.buckets.reduce(
            (subCatOutput, subCat) => {
              subCatOutput[subCat.slug] = {
                key: subCat.key,
                slug: subCat.slug,
                subSubCategories: subCat.subsubcategory.buckets.reduce(
                  (subSubCatOutput, subSubCat) => {
                    subSubCatOutput[subSubCat.slug] = {
                      key: subSubCat.key,
                      slug: subSubCat.slug,
                    };

                    return subSubCatOutput;
                  },
                  {}
                ),
              };

              return subCatOutput;
            },
            {}
          ),
        };
        return output;
      }, {});
    },
  },
});

const categoriesReducer = slice.reducer;

export const { mainCategories, esCategories } = slice.actions;
export { categoriesReducer };

import classnames from "classnames";
import React from "react";
import { FormattedMessage } from "react-intl";

import { ReactComponent as CheckmarkIcon } from "../images/icons/checkmark.svg";

import styles from "./FilterGrid.module.scss";

const FilterGrid = ({
  onChange,
  renderAsColors = false,
  selected = [],
  values,
}) => {
  if (!values || values.length === 0) {
    return null;
  }

  return (
    <ul className={classnames(styles.root, "siab-row")}>
      {values.map((item) => {
        const isSelected = selected.includes(item.key);
        return (
          <li
            key={`filter_colors_${item.key}`}
            className={classnames(
              styles.optionContainer,
              "col-2-xs col-3-xl col-4-xxl"
            )}
          >
            <FormattedMessage id="filters.select" defaultMessage="Select">
              {(label) => (
                <button
                  aria-label={label}
                  className={classnames(styles.option, {
                    [styles.optionSelected]: isSelected,
                  })}
                  onClick={() =>
                    onChange({ hasBeenRemoved: isSelected, value: item.key })
                  }
                  style={{
                    backgroundColor: renderAsColors ? item.bgColor : undefined,
                  }}
                >
                  {!renderAsColors && item.key}
                  {isSelected && (
                    <div className={styles.optionSelectedIconContainer}>
                      <CheckmarkIcon />
                    </div>
                  )}
                  {renderAsColors && (
                    <span className={styles.optionScreenReader}>
                      {item.bgColor}
                    </span>
                  )}
                </button>
              )}
            </FormattedMessage>
          </li>
        );
      })}
    </ul>
  );
};

export default FilterGrid;

export default function getShopsList(items = []) {
  return items.map(({ campaign: name, campaignID: id, deliveryTime, deliveryCosts, offers, price }) => ({
    deliveryCosts,
    deliveryTime,
    id,
    name,
    price,
    url: offers[0]?.url,
  }));
}

import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { FormattedNumber, FormattedNumberParts } from 'react-intl';
import * as d3 from 'd3-shape';
import { Range } from 'rc-slider';

import Checkbox from '../Checkbox/Checkbox';

import styles from './FilterPrice.module.scss';

const RangeSelector = ({ classList, currencySymbol, onChange, selected, values }) => {
  const itemsToRender = values.map((value) => ({
    key: `${currencySymbol}${value.from}` + (typeof value.to === 'number' ? ` - ${currencySymbol}${value.to}` : '+'),
    doc_count: value.doc_count,
    value: [value.from, value.to],
    selected: selected[0] === value.from && (!value.to || selected[1] === value.to),
    disabled: !value.doc_count,
  }));

  return (
    <div className={styles.priceRangesList}>
      <ul>
        {itemsToRender.map((item) => (
          <li key={item.key}>
            <Checkbox
              classList={{ root: classList.checkboxLabel, checkboxButton: classList.checkboxButton }}
              checked={item.selected}
              disabled={item.disabled}
              onChange={(e) => onChange({ hasBeenRemoved: !e.currentTarget.checked, value: item.value })}
              value={item.value}
            >
              <span key="text" className={styles.itemText}>
                <span className={styles.name} dangerouslySetInnerHTML={{ __html: item.key || '(n/a)' }}></span>
              </span>
              <span key="amount" className={styles.itemAmount}>
                ({item.doc_count})
              </span>
            </Checkbox>
          </li>
        ))}
      </ul>
    </div>
  );
};

RangeSelector.propTypes = {
  currencySymbol: PropTypes.string,
  onChange: PropTypes.func,
  selected: PropTypes.array,
  values: PropTypes.array,
};

function getCoordinates(items) {
  const maxAmount = Math.max(...items.map((item) => item.doc_count));
  const normalizedValues = items.map((item) => ({
    ...item,
    height: item.doc_count ? ~~((item.doc_count / maxAmount) * 90 + 10) : 0,
  }));

  const graphData = normalizedValues.map((item, index) => [index * (200 / items.length) + 10, 100 - item.height]);

  graphData.unshift([0, 100]);

  const getLineSVG = (data) =>
    d3
      .line()
      .x((d) => d[0])
      .y((d) => d[1])
      .curve(d3.curveCardinal)(data);

  return `${getLineSVG(graphData)} L${graphData[graphData.length - 1][0]}, 100 L${graphData[0][0]}, 100 z`;
}

const FilterPrice = ({
  classList,
  currencySymbol,
  items,
  onChange,
  ranges,
  selected,
  svgClipId = 'svg-clip',
  withHistogram = false,
}) => {
  const inputMinRef = useRef(null);
  const inputMaxRef = useRef(null);

  if (!(items && items.length)) {
    return null;
  }

  const minSelectable = parseInt(items[0].key, 10);
  const maxSelectable = parseInt(items[items.length - 1].key, 10);

  const [minSelected = minSelectable, maxSelected = maxSelectable] = selected.map((value) =>
    typeof value !== 'undefined' ? parseInt(value, 10) : undefined
  );


  const handleInputChange = () => {
    if (onChange) {
      onChange({
        hasBeenRemoved: false,
        value: [inputMinRef.current.value, inputMaxRef.current.value],
      });
    }
  };

  const renderKnobs = (offsetLeft, offsetRight) => ({ className, offset = 0, dragging, style = {}, value, index }) => {
    const handleStyle = Object.assign({}, style);
    handleStyle.left = offset + '%';

    const svgCurrent = typeof document !== 'undefined' && document.querySelector('.' + styles.svgChart + ' rect');

    if (index !== 0) {
      offsetRight = offset;
    } else {
      offsetLeft = offset;
    }

    if (svgCurrent) {
      if (index !== 0) {
        svgCurrent.setAttribute('width', offsetRight - offsetLeft + '%');
      } else {
        svgCurrent.setAttribute('x', offset + '%');
      }
    }

    return (
      <div key={className} className={className + (dragging ? ' dragging' : '')} style={handleStyle}>
        <div className={styles.tooltip}>
          <FormattedNumber value={value} />
        </div>
      </div>
    );
  };

  function renderHistogram(items) {
    if (items.length > 2) {
      const deltaBetweenBoundaries = maxSelectable - minSelectable;

      const coordinates = getCoordinates(items);
      const firstKnobPosition = minSelected ? (minSelected * 100) / deltaBetweenBoundaries : 0;
      const secondKnobPosition = maxSelected ? (maxSelected * 100) / deltaBetweenBoundaries - firstKnobPosition : 100;

      return (
        <svg viewBox={`0 -10 200 110`} version="1.1" className={styles.svgChart}>
          <defs>
            <clipPath id={svgClipId}>
              <path d={coordinates}></path>
            </clipPath>
          </defs>
          <path fill="#E9E9E9" d={coordinates}></path>
          <rect
            x={`${firstKnobPosition}%`}
            y="-10"
            width={`${secondKnobPosition}%`}
            height="100%"
            clipPath={`url(#${svgClipId})`}
          ></rect>
        </svg>
      );
    }

    return (
      <svg viewBox={`0 -10 200 110`} version="1.1" className={styles.svgChart}>
        <defs>
          <clipPath id={svgClipId}>
            <rect x="0%" y="75" width="100%" height="100%" clipPath={`url(#${svgClipId})`}></rect>
          </clipPath>
        </defs>
        <rect x="0" y="-10" width="100%" height="100%" clipPath={`url(#${svgClipId})`}></rect>
      </svg>
    );
  }

  return (
    <div>
      {withHistogram && (
        <>
          {renderHistogram(items)}
          <div className={styles.slider}>
            <Range
              defaultValue={[minSelected || minSelectable, maxSelected || maxSelectable]}
              handle={renderKnobs()}
              key={`${minSelected || minSelectable}_${maxSelected || maxSelectable}`}
              max={maxSelectable}
              min={minSelectable}
              onAfterChange={(value) => onChange({ hasBeenRemoved: false, value })}
              step={1}
            />
          </div>
        </>
      )}
      {ranges && ranges.length > 0 && (
        <FormattedNumberParts style="currency" value="0" currency={currencySymbol}>
          {([currency]) => (
            <RangeSelector
              classList={classList}
              currencySymbol={currency.value}
              onChange={onChange}
              selected={[minSelected, maxSelected]}
              values={ranges}
            />
          )}
        </FormattedNumberParts>
      )}
      <div className={styles.priceRange}>
        <label>
          <span>{currencySymbol}</span>
          <input
            defaultValue={minSelected || minSelectable}
            onBlur={handleInputChange}
            onKeyPress={(e) => (e.key === 'Enter' ? handleInputChange() : null)}
            ref={inputMinRef}
            tabIndex="3"
            type="number"
          />
          <span>-</span>
          <input
            defaultValue={maxSelected || maxSelectable}
            onBlur={handleInputChange}
            onKeyPress={(e) => (e.key === 'Enter' ? handleInputChange() : null)}
            ref={inputMaxRef}
            tabIndex="4"
            type="number"
          />
        </label>
      </div>
    </div>
  );
};

export default FilterPrice;

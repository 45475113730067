import { useDispatch, useSelector } from "react-redux";
import React from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

import { ReactComponent as NoResultsIcon } from "../images/icons/webshop-no-results.svg";

import styles from "./NoResultsPage.module.scss";
import { updateFilters } from "../reducers/searchPayloadReducer";

const NoResultPage = ({ categories, searchTerm }) => {
  const dispatch = useDispatch();

  const setCategory = (category) =>
    dispatch(updateFilters({ filter: "category", value: [category.slug] }));
  const mainCategories = useSelector(
    (state) => state.categories.mainCategories
  );
  return (
    <>
      <div
        className={classNames(styles.noResult, "col-12-xs")}
        id="readyForCrawler"
      >
        <NoResultsIcon />
        <div className={styles.content}>
          <h1>
            <FormattedMessage
              id="search.noResultsPage.noResults"
              defaultMessage="No results"
            />
          </h1>
          <p>
            <FormattedMessage
              id="search.noResultsPage.oops"
              defaultMessage="Oops, no results found for:"
            />
          </p>
          <p className={styles.searchTerm}>{searchTerm}</p>
          <ul>
            <li>
              <FormattedMessage
                id="search.noResultsPage.whatCanYouDo"
                defaultMessage="What can you do?"
              />
            </li>
            <li>
              <FormattedMessage
                id="search.noResultsPage.control"
                defaultMessage="Check the spelling of your search"
              />
            </li>
            <li>
              <FormattedMessage
                id="search.noResultsPage.lessSpecific"
                defaultMessage="Make your search less specific"
              />
            </li>
            <li>
              <FormattedMessage
                id="search.noResultsPage.different"
                defaultMessage="Try a different search"
              />
            </li>
          </ul>
        </div>
      </div>
      <div className={classNames(styles.categories, "col-12-xs")}>
        <h3>
          <FormattedMessage
            id="search.noResultsPage.browse"
            defaultMessage="Browse through the categories:"
          />
        </h3>
        <div className="siab-row">
          {Object.keys(mainCategories)
            .sort((a, b) =>
              mainCategories[a].key < mainCategories[b].key ? -1 : 1
            )
            .map((categoryKey, index) => {
              const category = mainCategories[categoryKey];
              if (!category.img) {
                return null;
              }

              const mouseOver = (e) => {
                e.currentTarget.querySelector("img").src = category.img_blue;
              };

              const mouseOut = (e) => {
                e.currentTarget.querySelector("img").src = category.img;
              };

              return (
                <div
                  className={classNames(
                    styles.categoryCard,
                    "col-6-xs col-3-m col-2-xl"
                  )}
                  onMouseOver={mouseOver}
                  onMouseLeave={mouseOut}
                  key={index}
                  onClick={() => setCategory(category.slug)}
                >
                  <img
                    src={category.img}
                    alt={category.name}
                    loading="lazy"
                    width={"50"}
                  />
                  <p>{category.name}</p>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

NoResultPage.displayName = "NoResultsPage";
export default NoResultPage;

import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./Product.module.scss";
import { FormattedNumberParts, FormattedNumber } from "react-intl";
import slugify from "../helpers/slugify";

const Product = React.memo(
  ({
    brand,
    classList = {},
    currency,
    shortDesc = "",
    EAN,
    id,
    image,
    images,
    isSelected = false,
    name,
    onClick,
    onClose,
    price,
    originalPrice,
    shop,
    SKU,
    url,
    viewOption
  }) => {
    const discount = ((originalPrice - price) / originalPrice) * 100;
    const priceValidUntilDate = new Date();
    priceValidUntilDate.setDate(priceValidUntilDate.getDate() + 1);

    return (
      <>
        {/* eslint-disable-next-line */}
        <a
          className={classnames(styles.root, classList.root, {
            [styles.column]: viewOption === "columns",
            [styles.tile]: viewOption === "tiles",
            [styles.selected]: isSelected,
          })}
          id={id}
          href={url}
          target="_blank"
        >
          <article>
            {discount >= 5 && (
              <span className={styles.discount}>{`-${Number(discount).toFixed(
                0
              )}%`}</span>
            )}
            {viewOption === "columns" && (
              <>
                <div className={(styles.productName, styles.title)}>{name}</div>
                <div className={styles.content}>
                  <div className={styles.productDescription}>{shortDesc}</div>
                  <div className={styles.productImage}>
                    <img alt={name} loading="lazy" src={image} title={name} />
                  </div>
                </div>
              </>
            )}
            {viewOption === "tiles" && (
              <>
                <div className={styles.productImage}>
                  <img alt={name} loading="lazy" src={image} title={name} />
                </div>
                <div className={styles.productName}>{name}</div>
              </>
            )}
            <div className={styles.productPrice}>
              {price && (
                <FormattedNumberParts
                  currency={currency}
                  style="currency"
                  value={price}
                >
                  {(parts) => {
                    const currencyPart = parts.find(
                      (part) => part.type === "currency"
                    );
                    const integerValue = parts.reduce(
                      (output, part) =>
                        part.type === "integer"
                          ? `${output}${part.value}`
                          : output,
                      ""
                    );
                    const decimalPart = parts.find(
                      (part) => part.type === "decimal"
                    );
                    const fractionPart = parts.find(
                      (part) => part.type === "fraction"
                    );

                    return (
                      <>
                        <span className={styles.productPriceCurrency}>
                          {currencyPart.value}
                        </span>
                        <span className={styles.productPriceValue}>
                          <FormattedNumber value={integerValue} />
                          {decimalPart.value}
                          {parseInt(fractionPart.value, 10) > 0
                            ? fractionPart.value
                            : "-"}
                        </span>
                      </>
                    );
                  }}
                </FormattedNumberParts>
              )}
            </div>
            <div className={styles.productShop}>{shop.name}</div>
          </article>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "Product",
                name,
                brand: {
                  "@type": "Thing",
                  name: brand || "",
                },
                shortDesc,
                gtin14: EAN,
                sku: SKU,
                image: images.slice(0, 4).map((image) => image.original),
                offers: [
                  {
                    "@type": "Offer",
                    url,
                    priceCurrency: currency,
                    price: parseFloat(price),
                    priceValidUntil: priceValidUntilDate
                      .toISOString()
                      .substr(0, 10),
                    itemCondition: "https://schema.org/NewCondition",
                    availability: "https://schema.org/InStock",
                    seller: {
                      "@type": "Organization",
                      name: shop.name,
                    },
                  },
                ],
              }),
            }}
          />
        </a>
      </>
    );
  }
);

Product.displayName = "Product";
Product.propTypes = {
  id: PropTypes.string,
  likedProducts: PropTypes.arrayOf(PropTypes.string),
};

export default Product;

import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";

import { ReactComponent as SearchIcon } from "../images/icons/search.svg";
import { ReactComponent as Close } from "../images/icons/close.svg";

// import CategoryPicker from '../CategoryPicker/CategoryPicker';
import {
  updateQuery,
  updateFilters,
  updateWpCategory,
  reset,
} from "../reducers/searchPayloadReducer";
import Suggestions from "../Suggestions/Suggestions";
import useSearchApi from "../helpers/useSearchApi";

import styles from "./SearchBar.module.scss";

let timeout;

const SearchBar = ({ categoryPicker = false, handleShowProducts, modal }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const initialQuery = useSelector((state) => state.searchPayload.payload.q);
  const [query, setQuery] = useState(initialQuery);
  const [showPlaceholder, setShowPlaceholder] = useState(
    initialQuery.length === 0
  );
  const esCats = useSelector((state) => state.categories.esCategories);
  const resetParams = () => dispatch(reset());
  const searchInput = useRef(null);

  useEffect(() => {
    if (!modal) {
      handleShowProducts(query.length > 0 ? query : false);
    }
  }, [handleShowProducts, query]);

  const { data: suggestions } = useSearchApi("/suggestions", {
    headers: {},
    method: "post",
    data: { filters: {}, query: query },
    shouldRun:
      window.matchMedia("(min-width: 767px)").matches &&
      !!query &&
      query.length > 1,
  });

  const [selected, setSelected] = useState(undefined);
  const [isOpen, setIsOpen] = useState(true);

  const triggerSelection = (index) => {
    setSelected(undefined);

    if (!suggestions) {
      return;
    }

    setIsOpen(false);
    setShowPlaceholder(true);

    if (suggestions.length === 0) {
      return;
    }

    if (
      suggestions[index]?.type === "shop" ||
      suggestions[index]?.type === "brand"
    ) {
      searchInput.current.value = "";
      const type =
        suggestions[index].type === "shop"
          ? "campaign"
          : suggestions[index].type;

      return dispatch(
        updateFilters({
          filter: type,
          value: [suggestions[index].text],
        })
      );
    }

    if (suggestions[index]?.type === "category") {
      searchInput.current.value = "";
      let wpCat;

      if (suggestions[index].catRoute.length === 1) {
        wpCat = Object.values(esCats).find(
          (mainCat) => mainCat.key === suggestions[index].catRoute[0]
        ).slug;
      } else if (suggestions[index].catRoute.length === 2) {
        const main = Object.values(esCats).find(
          (mainCat) => mainCat.key === suggestions[index].catRoute[0]
        );
        wpCat = Object.values(main.subCategories).find(
          (subCat) => subCat.key === suggestions[index].catRoute[1]
        ).slug;
      } else if (suggestions[index].catRoute.length === 3) {
        const main = Object.values(esCats).find(
          (mainCat) => mainCat.key === suggestions[index].catRoute[0]
        );
        const sub = Object.values(main.subCategories).find(
          (subCat) => subCat.key === suggestions[index].catRoute[1]
        );

        wpCat = Object.values(sub.subSubCategories).find(
          (subSubCat) => subSubCat.key === suggestions[index].catRoute[2]
        ).slug;
      }

      return dispatch(
        updateWpCategory({
          wpCategory: wpCat,
          category: suggestions[index].catRoute,
        })
      );
    }

    setShowPlaceholder(false);
    searchInput.current.value = suggestions[index].text;
    return dispatch(updateQuery({ q: suggestions[index].text }));
  };

  const selectedItem = (e) => {
    if (!suggestions) {
      return;
    }

    if (!isOpen) {
      return;
    }

    if (e.key === "Escape") {
      setIsOpen(false);
      return;
    }

    if (e.key === "Enter") {
      setIsOpen(false);

      if (!(selected === undefined)) triggerSelection(selected);
      return;
    }

    if (e.key === "ArrowDown") {
      e.preventDefault();

      if (
        (suggestions && suggestions.length - 1 === selected) ||
        selected === undefined
      ) {
        return setSelected(0);
      }

      return setSelected(selected + 1);
    }

    if (e.key === "ArrowUp") {
      e.preventDefault();

      if (selected === 0 || selected === undefined) {
        return setSelected(suggestions && suggestions.length - 1);
      }

      return setSelected(selected - 1);
    }

    if (e.key === "Tab") {
      setIsOpen(false);
    }
  };

  const clearSearch = () => {
    resetParams();
    searchInput.current.value = "";
    setShowPlaceholder(true);
  };

  return (
    <div className={styles.searchBar}>
      <div
        className={classNames(styles.inputContainer, {
          [styles.noCategoryPicker]: !categoryPicker,
        })}
      >
        {/* <CategoryPicker
          classList={{ root: classNames(styles.categoryPicker, "col-2-xl") }}
          selectedCategory={selectedCategory}
        /> */}
        <span className={styles.searchIcon}>
          <SearchIcon />
        </span>
        <div
          className={classNames(styles.searchPlaceholder, {
            withoutCategory: !categoryPicker,
          })}
        >
          {showPlaceholder && (
            <label htmlFor="searchBarInput">
              <FormattedMessage
                defaultMessage="Search by product or shop e.g. <b>mobile phone</b>, <b>sneakers</b> or <b>Sun & Sand Sports</b>"
                id="search.placeholder"
              >
                {(text) => (
                  <div className={styles.placeholderText}>{parse(text)}</div>
                )}
              </FormattedMessage>
            </label>
          )}
          <input
            autoComplete="off"
            className={classNames(styles.searchInput)}
            data-testid="searchbarInput"
            id="searchBarInput"
            defaultValue={query}
            onMouseDown={(e) => {
              if (isOpen) {
                e.stopPropagation();
              }
            }}
            onFocus={(e) => {
              if (e.currentTarget.value.length > 0) {
                setIsOpen(true);
              }
            }}
            onInput={(e) => {
              const value = e.currentTarget.value;
              setShowPlaceholder(value.length === 0);

              if (value === query) {
                return;
              }

              if (timeout) {
                clearTimeout(timeout);
              }

              timeout = setTimeout(() => {
                setIsOpen(true);

                // window.scrollTo({
                //   behavior: "smooth",
                //   left: 0,
                //   top: 0,
                // });

                setQuery(value);
                dispatch(updateQuery({ value }));
              }, 450);
            }}
            onKeyDown={selectedItem}
            ref={searchInput}
          />
        </div>
        <div className={classNames("")}>
          <div className={styles.searchIcons}>
            {searchInput.current && searchInput.current.value.length > 0 && (
              <Close
                className={styles.clearIcon}
                onClick={clearSearch}
                data-testid="clearInput"
              />
            )}
            <button
              aria-label={intl.messages["common.search"]}
              className={styles.searchButton}
              onClick={() => setIsOpen(false)}
            >
              <SearchIcon fill="#FFF" />
              <span>
                <FormattedMessage id="common.search" defaultMessage="Search" />
              </span>
            </button>
          </div>
        </div>
      </div>
      {query.length > 1 && isOpen && (
        <Suggestions
          onClose={() => setIsOpen(false)}
          onSelect={(idx) => {
            setSelected(idx);
            triggerSelection(idx);
          }}
          query={query}
          selected={selected}
          suggestions={suggestions}
        />
      )}
    </div>
  );
};

SearchBar.displayName = "Searchbar";
SearchBar.propTypes = {
  query: PropTypes.string,
};

export default SearchBar;

export default function getSortedColors(arrToBeSorted, { colorMap = {} }) {
  const colorMapKeys = Object.keys(colorMap);

  return arrToBeSorted
    .map((item) => {
      item.bgColor = colorMap[item.key];
      return item;
    })
    .sort((a, b) => (colorMapKeys.indexOf(a.key) < colorMapKeys.indexOf(b.key) ? -1 : 1));
}

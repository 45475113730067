import React from "react";
import PropTypes from "prop-types";

import styles from "./ProductDetailsCard.module.scss";

import uniqBy from "../helpers/uniqBy";
import sortColors from "../helpers/sortColors";
import slugify from "../helpers/slugify";
import sortSizes from "../helpers/sortSizes";
import generateShopUrl from "../helpers/generateShopUrl";

import Price from "./Price";
import PropertySize from "../PropertySize/PropertySize";
import PropertyColor from "../PropertyColor/PropertyColor";
import PropertyCarousel from "../PropertyCarousel/PropertyCarousel";

export const ProductDetails = ({
  shopInfoData,
  shopsDataBody,
  product,
  hasPriceChecker,
  setImage,
  onClickSizes,
  size,
  isTablet,
}) => {
  const { id, originalPrice, currency, price, shortDesc } = product;
  const { shops: shopsData, aggregations: shopsDataAggregation } =
    shopsDataBody || {};

  const onClickColor = (color) => {
    const productUrl = 
      `${process.env.REACT_APP_SITE_VERSION}/product/${slugify(color.productName)}/${color.productId}/`
    ;

    window.open(productUrl, "_blank", "noopener,noreferrer");
  };

  const onMouseEnterColor = (color) => {
    setImage(color.path);
  };

  const onMouseLeaveColor = (color) => {
    setImage(null);
  };

  const getPropertyColorsFromProduct = (selectedValue) => {
    let components = [];
    let selectedIndex = 0;

    if (
      shopsData &&
      shopsData.length > 0 &&
      shopsData[0].colors &&
      shopsData[0].colors.length > 0
    ) {
      components = uniqBy(shopsData[0].colors, "path")
        .sort(sortColors)
        .map((col, index) => {
          const selected = col.productId === selectedValue; // product.id

          if (selected) {
            selectedIndex = index;
          }

          return (
            <PropertyColor
              key={col.productId}
              color={col}
              selected={selected}
              onClickColor={onClickColor}
              onMouseEnterColor={onMouseEnterColor}
              onMouseLeaveColor={onMouseLeaveColor}
            />
          );
        });
    }

    return {
      components,
      selectedIndex,
    };
  };

  const getPropertySizesFromProduct = (selectedValue) => {
    let components = [];
    let selectedIndex = 0;

    if (shopsDataAggregation?.nested.sizes?.buckets.length > 0) {
      const sizes = shopsDataAggregation.nested.sizes.buckets.map(
        (item) => item.key
      );
      components = sortSizes(sizes).map((item, index) => {
        const selected = item === selectedValue; // state.size
        if (selected) {
          selectedIndex = index;
        }

        return (
          <PropertySize
            key={item}
            size={item}
            selected={selected}
            onClickSizes={onClickSizes}
          />
        );
      });
    }
    return {
      components,
      selectedIndex,
    };
  };

  const propertyColors = hasPriceChecker && getPropertyColorsFromProduct(id);
  const propertySizes = hasPriceChecker && getPropertySizesFromProduct(size);

  return (
    <>
      {isTablet && (
        <>
          <div className={styles.shopLogoAndPrice}>
            {shopInfoData && (
              <a
                className={styles.shopLogo}
                to={`${
                  process.env.REACT_APP_SITE_VERSION
                }/shop/${generateShopUrl(shopInfoData.shop_website)}/`}
              >
                <img
                  alt={shopInfoData.shop_name}
                  src={shopInfoData.shop_logo}
                  width="175"
                />
              </a>
            )}
            <div className={styles.price}>
              {originalPrice !== 0 && (
                <span>
                  <Price
                    currency={currency}
                    originalPrice
                    price={originalPrice}
                  />
                </span>
              )}
              <Price currency={currency} price={price} />
            </div>
          </div>
        </>
      )}
      <div className={styles.description}>{shortDesc}</div>
      <div className={styles.sizesAndColorsSelections}>
        {hasPriceChecker &&
          propertyColors.components &&
          propertyColors.components.length > 1 && (
            <PropertyCarousel
              components={propertyColors.components}
              selectedIndex={propertyColors.selectedIndex}
              colors
            />
          )}
        {hasPriceChecker &&
          propertySizes.components &&
          propertySizes.components.length > 0 && (
            <PropertyCarousel
              components={propertySizes.components}
              selectedIndex={propertySizes.selectedIndex}
              sizes
            />
          )}
      </div>
    </>
  );
};

ProductDetails.propTypes = {
  shopInfoData: PropTypes.object.isRequired,
  shopsDataBody: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  hasPriceChecker: PropTypes.bool.isRequired,
  config: PropTypes.object.isRequired,
  setImage: PropTypes.func.isRequired,
  onClickSizes: PropTypes.func.isRequired,
  //size: PropTypes.oneOfType([PropTypes.number, PropTypes.object]).isRequired,
};

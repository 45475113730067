import PropTypes from "prop-types";
import React, { useLayoutEffect, useState, useRef } from "react";

import { openCardAnimation, closeCardAnimation } from "./cardAnimation";
import getCDNImage from "../helpers/getCDNImage";

//import { VIEW_OPTIONS_COLUMNS, VIEW_OPTIONS_TILES } from '../SearchResultsPage/SearchResultsPage';
// import { MobilePDCLayout } from './MobilePDCLayout';
import { ExtendedPDCLayout } from "./ExtendedPDCLayout";
import { ProductDetails } from "./ProductDetails";
import { Shops } from "./Shops";

const ProductDetailsCard = React.memo(
  ({
    classList = {},
    isTablet,
    onClose,
    onClosed,
    product,
    view,
    viewState,
    shopsDataBody,
    shopInfoData,
    config,
  }) => {
    const { brand, campaignID, GTIN, images, ISBN, name, offers } = product;
    const { SKU, id, EAN, shops, family_code } = product;
    const hasPriceChecker =
      EAN || GTIN || ISBN || (brand && SKU) || (campaignID && family_code);
    const { shops: shopsData = [] } = shopsDataBody || {};

    const [image, setImage] = useState(null);
    //only used for Gallery props, uses 'image' from state
    const galleryImages = images.slice(0, 4).map((item, index) => ({
      original: image ? image : getCDNImage(images[index]),
      thumbnail: getCDNImage(images[index]),
      originalAlt: name,
      thumbnailAlt: name + " - thumbnail",
      originalTitle: name,
      thumbnailTitle: name + " - thumbnail",
    }));

    const [size, setSize] = useState(null);

    const onUpdateSize = (newSize) => {
      setSize(size === newSize ? null : newSize);
    };

    const getShopsToDisplay = () => {
      if (hasPriceChecker) {
        return shopsData && shopsData.length > 0
          ? shopsData.filter((shop) => {
              if (!size) {
                return true;
              }

              if (size && !shop.offers) {
                return false;
              }
              return shop.offers.some(
                (item) => item.size && item.size.includes(size)
              );
            })
          : [];
      }

      shops[0].offers = offers;
      shops[0].campaign = shops[0].name;
      shops[0].deliveryCosts = shops[0].deliveryCosts || 0;
      return shops;
    };

    const shopsToDisplay = getShopsToDisplay();
    const isAllShopsBtnShown = shopsToDisplay.length > 3;

    const ref = useRef(null);
    const detailsCardNode = ref.current;
    useLayoutEffect(() => {
      if (detailsCardNode) {
        const maxCardHeight = Array.from(detailsCardNode.children).reduce(
          (contentHeight, child) => (contentHeight += child.offsetHeight),
          0
        );
        const shopsMarginBottom = 20;
        const showAllShopsHeight = isAllShopsBtnShown ? 34 : 0;
        if (viewState.isOpening)
          openCardAnimation(
            detailsCardNode,
            maxCardHeight + showAllShopsHeight + shopsMarginBottom,
            isAllShopsBtnShown
          );
        else if (viewState.isClosing)
          closeCardAnimation(
            detailsCardNode,
            maxCardHeight + showAllShopsHeight,
            onClosed,
            isAllShopsBtnShown
          );
      }
    }, [
      detailsCardNode,
      isAllShopsBtnShown,
      onClosed,
      viewState.isClosing,
      viewState.isOpening,
    ]);

    if (hasPriceChecker) {
      shopsData.forEach((item) => {
        item.colors = [
          ...item.innerHits.map((innerItem) => ({
            path: getCDNImage(innerItem._source.images[0]),
            productId: innerItem._id,
            productName: innerItem._source.name,
          })),
        ];
      });
    }

    const productDetailsProps = {
      shopInfoData,
      shopsDataBody,
      product,
      hasPriceChecker: !!hasPriceChecker,
      config,
      setImage,
      onClickSizes: onUpdateSize,
      size,
      isTablet,
    };
    const extendedLayoutProps = {
      viewState,
      classList,
      PDCref: ref,
      isAllShopsBtnShown,
    };
    const commonLayoutProps = {
      product,
      galleryImages,
      config,
      view,
      productDetails: <ProductDetails {...productDetailsProps} />,
      onClose,
      shops: shopsToDisplay.length > 0 && (
        <Shops
          isTablet={isTablet}
          product={product}
          shopsList={shopsToDisplay}
        />
      ),
    };

    // const cardLayout = isTablet ? (
    //   <ExtendedPDCLayout {...commonLayoutProps} {...extendedLayoutProps} />
    // ) : (
    //   <MobilePDCLayout {...commonLayoutProps} />
    // );

    // return cardLayout;
    return (
      <ExtendedPDCLayout {...commonLayoutProps} {...extendedLayoutProps} />
    );
  }
);

ProductDetailsCard.displayName = "ProductDetailsCard";
ProductDetailsCard.propTypes = {
  classList: PropTypes.object.isRequired,
  isTablet: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClosed: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  //view: PropTypes.oneOf([VIEW_OPTIONS_COLUMNS, VIEW_OPTIONS_TILES]),
  viewState: PropTypes.object.isRequired,
  anonymousLikedProducts: PropTypes.array.isRequired,
  config: PropTypes.object.isRequired,
  setLikedProductsAnonymous: PropTypes.func.isRequired,
  setRecentlyViewProductsAnonymous: PropTypes.func.isRequired,
  setUserProfile: PropTypes.func.isRequired,
  shopsDataBody: PropTypes.object.isRequired,
  shopInfoData: PropTypes.object.isRequired,
};

export default ProductDetailsCard;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { ReactComponent as FacebookIcon } from '../images/icons/facebook.svg';
import { ReactComponent as TwitterIcon } from '../images/icons/twitter.svg';
// import { ReactComponent as LinkedinIcon } from '../images/icons/linkedin.svg';
import { ReactComponent as EmailIcon } from '../images/icons/email.svg';
import { ReactComponent as WhatsappIcon } from '../images/icons/whatsapp.svg';

import styles from './Share.module.scss';
const isSafari =
  // eslint-disable-next-line no-restricted-globals
  typeof self === 'object' &&
  navigator.userAgent.indexOf('Safari') !== -1 &&
  navigator.userAgent.indexOf('Chrome') === -1;

const Share = ({ text = '', withEmail, large, intl, config, withName, modal, overlay, floating, url }) => {
  if (url.startsWith('/')) {
    url = config.baseUrl + url;
  }

  const share = intl.messages['sharing.shareOn'];
  const shareThrough = intl.messages['sharing.shareThrough'];

  const shareLinks = [
    {
      name: `${share} Facebook`,
      url: `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${text}`,
      icon: <FacebookIcon />,
      className: 'facebook',
    },
    {
      name: `${share} Twitter`,
      url: `https://twitter.com/share?url=${url}&text=${text}`,
      icon: <TwitterIcon />,
      className: 'twitter',
    },
  ];

  if (!isSafari) {
    shareLinks.push({
      name: `${share} Whatsapp`,
      url: `https://api.whatsapp.com/send?text=${text} ${url}`,
      icon: <WhatsappIcon />,
      className: 'whatsapp',
    });
  }

  if (withEmail) {
    shareLinks.push({
      name: `${shareThrough} e-mail`,
      url: `mailto:%20?body=${text} ${url}`,
      icon: <EmailIcon />,
      className: 'email',
    });
  }

  return (
    <ul
      className={classNames({
        [styles.shareLarge]: large,
        [styles.share]: !large,
        [styles.modal]: modal,
        [styles.overlay]: overlay,
        [styles.floating]: floating,
      })}
    >
      {shareLinks.map((link, index) => {
        return (
          <li className={styles.shareItem} key={link.icon + index}>
            <a href={link.url} target="_blank" rel="noopener noreferrer" className={styles[link.className]}>
              {link.icon}
              {withName && link.name}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

Share.propTypes = {
  url: PropTypes.string,
  text: PropTypes.string,
  withEmail: PropTypes.bool,
  large: PropTypes.bool,
  config: PropTypes.object,
};

Share.displayName = 'Share';

const mapStateToProps = ({ config, intl }) => ({ config, intl });
export default connect(mapStateToProps)(Share);

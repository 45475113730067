import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Swipeable } from 'react-swipeable';

import { ReactComponent as ArrowLeftIcon } from '../images/icons/arrowLeft.svg';
import { ReactComponent as ArrowRightIcon } from '../images/icons/arrowRight.svg';

import styles from './PropertyCarousel.module.scss';

const PROPERTY_WIDTH = 71;
const ARROW_WIDTH = 32;

const PropertyCarousel = ({ components, selectedIndex, colors, sizes }) => {
  const [enableArrows, setEnableArrows] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [propertyIndex, setPropertyIndex] = useState(0);
  const [maxProperties, setMaxProperties] = useState(2);

  const containerEl = useRef(null);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    setTimeout(() => handleResize(), 10);

    return () => window.removeEventListener('resize', handleResize);
  });

  const handleLeftScroll = (ev) => {
    ev.preventDefault();
    if (propertyIndex > 0) {
      setPropertyIndex(propertyIndex - 1);
    }
  };

  const handleRightScroll = (ev) => {
    ev.preventDefault();
    const totalProperties = components.length;
    const maxPropertyIndex = Math.max(totalProperties - maxProperties, 0);

    if (propertyIndex < maxPropertyIndex) {
      setPropertyIndex(propertyIndex + 1);
    }
  };

  const handleResize = () => {
    if (!(containerEl && containerEl.current)) {
      return;
    }

    const totalProperties = components.length;
    const newEnableArrows = containerEl.current.clientWidth > 375;
    const arrowWidth = newEnableArrows ? 2 * ARROW_WIDTH : 0;
    const newMaxProperties = Math.floor((containerEl.current.clientWidth - arrowWidth) / PROPERTY_WIDTH);
    const maxPropertyIndex = Math.max(totalProperties - newMaxProperties, 0);
    const newInitialized = initialized ? initialized : !initialized;
    const newPropertyIndex = initialized ? propertyIndex : selectedIndex;

    if (initialized && newMaxProperties === maxProperties && newEnableArrows === enableArrows) {
      return;
    }

    setInitialized(newInitialized);
    setPropertyIndex(newPropertyIndex > maxPropertyIndex ? maxPropertyIndex : newPropertyIndex);
    setMaxProperties(newMaxProperties);
    setEnableArrows(newEnableArrows);
  };

  const totalWidth = components.length * PROPERTY_WIDTH;

  return (
    <div className={styles.propertyCarousel} ref={containerEl}>
      {components.length > maxProperties && enableArrows && (
        <p
          className={classNames(styles.scrollButton, { [styles.scrollButtonDisabled]: propertyIndex === 0 })}
          onClick={handleLeftScroll}
        >
          <ArrowLeftIcon />
        </p>
      )}
      <Swipeable onSwipedLeft={handleRightScroll} onSwipedRight={handleLeftScroll} trackMouse>
        <div
          className={classNames(styles.properties, styles.colors, styles.sizes)}
          style={{ width: `${maxProperties * PROPERTY_WIDTH}px` }}
        >
          <ul style={{ width: `${totalWidth}px`, transform: `translateX(${propertyIndex * -PROPERTY_WIDTH}px)` }}>
            {components}
          </ul>
        </div>
      </Swipeable>
      {components.length > maxProperties && enableArrows && (
        <p
          className={classNames(styles.scrollButton, {
            [styles.scrollButtonDisabled]: propertyIndex === Math.max(components.length - maxProperties, 0),
          })}
          onClick={handleRightScroll}
        >
          <ArrowRightIcon />
        </p>
      )}
    </div>
  );
};

PropertyCarousel.propTypes = {
  components: PropTypes.array,
  selectedIndex: PropTypes.number,
  colors: PropTypes.bool,
  sizes: PropTypes.bool,
};

PropertyCarousel.defaultProps = {
  components: [],
  selectedIndex: 0,
  colors: false,
  sizes: false,
};

PropertyCarousel.displayName = 'PropertyCarousel';
export default PropertyCarousel;

import classNames from "classnames";
import React from "react";

import Drawer from "../Drawer/Drawer";

import styles from "./DrawerToMenu.module.scss";

export const DrawerContent = ({
  children,
  direction,
  isNested = false,
  onOverlayClick,
}) => {
  const isNotDesktop = !window.matchMedia("(min-width: 1440px)").matches;

  return isNotDesktop ? (
    <Drawer
      key={isNotDesktop}
      direction={direction}
      onOverlayClick={onOverlayClick}
      isNested
    >
      {children}
    </Drawer>
  ) : null;
};

export const MenuContent = ({ children, shouldBeOpen, classList = {} }) => {
  const isDesktop = window.matchMedia("(min-width: 1440px)").matches;

  return isDesktop && shouldBeOpen ? (
    <div key={isDesktop} className={classNames(styles.menu, classList.root)}>
      {children}
    </div>
  ) : null;
};

DrawerContent.displayName = "DrawerContent";
export default React.memo(function DrawerToMenu({ children }) {
  return children;
});

/**
 * @typedef {Object.<string, string>} Locales
 *
 * Flattens the messages object coming from the locales because nested messages objects not supported anymore by react intl
 *
 * @param {Object} nestedMessages Messages object to be flattened
 * @param {string} prefix String to prefix the properties within the file
 * @returns {Locales}
 */
export default function flattenMessages(nestedMessages, prefix = "") {
  if (!nestedMessages) {
    return {};
  }

  return Object.getOwnPropertyNames(nestedMessages).reduce(
    (finalMsgObj, prop) => {
      const prefixedKey = `${prefix ? `${prefix}.` : ""}${prop}`;
      let value = nestedMessages[prop];

      if (typeof nestedMessages[prop] === "object") {
        return Object.assign(finalMsgObj, flattenMessages(value, prefixedKey));
      }

      if (typeof value === "string" && value.includes("{{")) {
        value = value.replace("{{", "{").replace("}}", "}");
      }

      return Object.assign(finalMsgObj, { [prefixedKey]: value });
    },
    {}
  );
}

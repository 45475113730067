import classnames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

import { updateWpCategory } from "../reducers/searchPayloadReducer";

import { ReactComponent as ArrowDownIcon } from "../images/icons/arrowDown.svg";
import { ReactComponent as ArrowUpIcon } from "../images/icons/arrowUp.svg";

import styles from "./FilterCategories.module.scss";

function getItemsSortedAlphabetically(arr) {
  return [...arr].sort((a, b) => (a.key < b.key ? -1 : 1));
}

function getTop10ItemsSortedAlphabetically(arr) {
  return getItemsSortedAlphabetically([...arr].slice(0, 10));
}

const FilterCategories = ({
  filter = [],
  categories: providedCategories = [],
}) => {
  const dispatch = useDispatch();
  const [showingMore, setShowingMore] = useState(false);

  const categories = !showingMore
    ? getTop10ItemsSortedAlphabetically(providedCategories)
    : getItemsSortedAlphabetically(providedCategories);

  let filteredCategory;
  if (filter.length > 0) {
    filteredCategory = categories.find((cat) => cat.key === filter[0]);
    if (!filteredCategory) {
      return null;
    }
  }

  let subCategories = [];
  let subSubCategories = [];

  if (categories.length === 0) {
    return null;
  }

  if (filter.length > 0) {
    subCategories = !showingMore
      ? getTop10ItemsSortedAlphabetically(filteredCategory.subcategory.buckets)
      : getItemsSortedAlphabetically(filteredCategory.subcategory.buckets);
  }

  if (filter.length > 1) {
    subSubCategories = !showingMore
      ? getTop10ItemsSortedAlphabetically(
          filteredCategory.subcategory.buckets.find(
            (subCat) => subCat.key === filter[1]
          ).subsubcategory.buckets
        )
      : getItemsSortedAlphabetically(
          filteredCategory.subcategory.buckets.find(
            (subCat) => subCat.key === filter[1]
          ).subsubcategory.buckets
        );
  }

  let shouldRenderShowMoreButton = providedCategories.length > 10;

  if (filter.length === 1) {
    shouldRenderShowMoreButton = categories[0].subcategory.buckets.length > 10;
  } else if (filter.length > 1) {
    shouldRenderShowMoreButton =
      categories[0].subcategory.buckets[0].subsubcategory.buckets.length > 10;
  }

  return (
    <ul className={styles.rootCategories}>
      {categories.map(
        (rootCat) =>
          (filter.length === 0 || filter[0] === rootCat.key) && (
            <li key={rootCat.slug}>
              <a
                className={classnames({
                  [styles.filterCategorySelected]:
                    filter.length > 0 && rootCat.key === filter[0],
                })}
                onClick={() =>
                  dispatch(
                    updateWpCategory({
                      wpCategory: rootCat.slug,
                      category: [rootCat.key],
                    })
                  )
                }
              >
                <span className={styles.categoryName}>{rootCat.key}</span>
                <span className={styles.categoryDocsCount}>
                  ({rootCat.doc_count})
                </span>
              </a>
              {filter.length === 1 && filter[0] === rootCat.key && (
                <ul className={styles.subCategories}>
                  {subCategories.map((subCat) => (
                    <li key={subCat.slug}>
                      <a
                        className={classnames({
                          [styles.filterCategorySelected]:
                            filter.length > 1 && subCat.key === filter[1],
                        })}
                        onClick={() =>
                          dispatch(
                            updateWpCategory({
                              wpCategory: subCat.slug,
                              category: [rootCat.key, subCat.key],
                            })
                          )
                        }
                      >
                        <span className={styles.categoryName}>
                          {subCat.key}
                        </span>
                        <span className={styles.categoryDocsCount}>
                          ({subCat.doc_count})
                        </span>
                      </a>
                      {filter.length > 1 && filter[1] === subCat.key && (
                        <ul className={styles.subSubCategories}>
                          {subSubCategories.map((subSubCat) => {
                            return (
                              <li key={subSubCat.slug}>
                                <a
                                  className={classnames({
                                    [styles.filterCategorySelected]:
                                      filter.length > 2 &&
                                      subSubCat.key === filter[2],
                                  })}
                                  onClick={() =>
                                    dispatch(
                                      updateWpCategory({
                                        wpCategory: subSubCat.slug,
                                        category: [
                                          rootCat.key,
                                          subCat.key,
                                          subSubCat.key,
                                        ],
                                      })
                                    )
                                  }
                                >
                                  <span className={styles.categoryName}>
                                    {subSubCat.key}
                                  </span>
                                  <span className={styles.categoryDocsCount}>
                                    ({subSubCat.doc_count})
                                  </span>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              )}
              {filter.length > 1 && filter[0] === rootCat.key && (
                <ul className={styles.subCategories}>
                  {subCategories.map(
                    (subCat) =>
                      filter.length > 1 &&
                      filter[1] === subCat.key && (
                        <li key={subCat.slug}>
                          <a
                            className={classnames({
                              [styles.filterCategorySelected]:
                                filter.length > 1 && subCat.key === filter[1],
                            })}
                            onClick={() =>
                              dispatch(
                                updateWpCategory({
                                  wpCategory: subCat.slug,
                                  category: [rootCat.key, subCat.key],
                                })
                              )
                            }
                          >
                            <span className={styles.categoryName}>
                              {subCat.key}
                            </span>
                            <span className={styles.categoryDocsCount}>
                              ({subCat.doc_count})
                            </span>
                          </a>
                          {filter.length > 1 && filter[1] === subCat.key && (
                            <ul className={styles.subSubCategories}>
                              {subSubCategories.map((subSubCat) => {
                                return (
                                  <li key={subSubCat.slug}>
                                    <a
                                      className={classnames({
                                        [styles.filterCategorySelected]:
                                          filter.length > 2 &&
                                          subSubCat.key === filter[2],
                                      })}
                                      onClick={() =>
                                        dispatch(
                                          updateWpCategory({
                                            wpCategory: subSubCat.slug,
                                            category: [
                                              rootCat.key,
                                              subCat.key,
                                              subSubCat.key,
                                            ],
                                          })
                                        )
                                      }
                                    >
                                      <span className={styles.categoryName}>
                                        {subSubCat.key}
                                      </span>
                                      <span
                                        className={styles.categoryDocsCount}
                                      >
                                        ({subSubCat.doc_count})
                                      </span>
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </li>
                      )
                  )}
                </ul>
              )}
            </li>
          )
      )}
      {shouldRenderShowMoreButton && (
        <li className={styles.toggleCategoriesBtnContainer}>
          <button
            className={styles.toggleCategoriesBtn}
            onClick={() => setShowingMore((currentValue) => !currentValue)}
          >
            {!showingMore ? (
              <>
                <FormattedMessage
                  defaultMessage="Show more"
                  id="common.showMore"
                />
                <ArrowDownIcon className={styles.toggleDirectionIcon} />
              </>
            ) : (
              <>
                <FormattedMessage
                  defaultMessage="Show less"
                  id="common.showLess"
                />
                <ArrowUpIcon className={styles.toggleDirectionIcon} />
              </>
            )}
          </button>
        </li>
      )}
    </ul>
  );
};

FilterCategories.displayName = "FilterCategories";
FilterCategories.propTypes = {
  filter: PropTypes.arrayOf(PropTypes.string),
  categories: PropTypes.array.isRequired,
};

export default FilterCategories;

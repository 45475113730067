import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

const configAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt),
});

const slice = createSlice({
  name: "config",
  initialState: configAdapter.getInitialState({
    baseUrl: process.env.REACT_APP_BASE_URL,
    country: process.env.REACT_APP_WEBSITE_COUNTRY,
    googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    shopName: process.env.REACT_APP_WEBSITE_DOMAIN,
    affiliateId: process.env.REACT_APP_AFFILIATEID,
    defaults: {},
  }),
  reducers: {
    defaults: (state, action) => {
      state.defaults = { ...action.payload.defaults };
      state.defaults.cmsSlugs = {
        ...action.payload.defaults.cmsSlugs,
        profile: action.payload.common.profile.header.toLowerCase(),
        search: action.payload.common.common.search.toLowerCase(),
      };
    },
  },
});

const configReducer = slice.reducer;

export const { defaults } = slice.actions;
export { configReducer };

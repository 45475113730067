import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import styles from './TextInput.module.scss';

export const VALIDATE_VALID = 'valid';
export const VALIDATE_INVALID = 'invalid';
export const ICON_POSITION_LEFT = 'left';
export const ICON_POSITION_NONE = 'none';
export const ICON_POSITION_RIGHT = 'right';

const TextInput = ({
  classList = {},
  disabled = false,
  forId = '',
  Helper,
  Icon,
  iconPosition = ICON_POSITION_NONE,
  Label,
  placeholder = '',
  validate = '',
  ...props
}) => {
  const rootStyle = classNames(styles.root, classList.root, {
    [styles.disabled]: disabled,
    [styles.valid]: validate === VALIDATE_VALID,
    [styles.invalid]: validate === VALIDATE_INVALID,
  });

  return (
    <label className={rootStyle} data-testid={props['data-testid']} htmlFor={forId}>
      <span className={classNames(styles.label, 'captionLarge')}>{Label}</span>
      <div className={classNames(styles.inputContainer, classList.inputContainer, styles[iconPosition])}>
        <div className={classNames(styles.icon, classList.icon)}>{Icon}</div>
        <input
          {...props}
          id={forId}
          className={classNames(styles.input, classList.input)}
          data-testid={undefined}
          disabled={disabled}
          placeholder={placeholder}
        />
      </div>
      <span className="caption helper">{Helper}</span>
    </label>
  );
};

TextInput.displayName = 'TextInput';

TextInput.propTypes = {
  classList: PropTypes.objectOf(PropTypes.string),
  iconPosition: PropTypes.oneOf([ICON_POSITION_LEFT, ICON_POSITION_RIGHT]),
  Helper: PropTypes.object,
  Icon: PropTypes.object,
  placeholder: PropTypes.string,
  validate: PropTypes.oneOf(['valid', 'invalid', '']),
};

export default TextInput;

import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import ImageGallery from 'react-image-gallery';

import { ReactComponent as ArrowLeftIcon } from '../images/icons/arrowLeft.svg';
import { ReactComponent as ArrowRightIcon } from '../images/icons/arrowRight.svg';

import styles from './Gallery.module.scss';

const Gallery = ({ sticky = false, images, onImageGallerySlide }) => {
  const galleryEl = useRef(null);

  useEffect(() => {
    if (sticky) {
      window.addEventListener('scroll', updateGalleryPosition);
      window.addEventListener('resize', updateGalleryPosition);
      setTimeout(updateGalleryPosition, 100);
    }

    return () => {
      window.removeEventListener('scroll', updateGalleryPosition);
      window.removeEventListener('resize', updateGalleryPosition);
    };
  }, [sticky]);

  const updateGalleryPosition = () => {
    if (!(galleryEl && galleryEl.current)) {
      return;
    }

    const parentHeight = galleryEl.current.parentElement.parentElement.getBoundingClientRect().height;
    const height = galleryEl.current.getBoundingClientRect().height;
    const scrollableHeight = parentHeight - height;

    galleryEl.current.style.transform =
      'translateY(' + (window.scrollY >= scrollableHeight ? scrollableHeight + 'px' : window.scrollY + 'px') + ')';
  };

  const renderLeftNav = (onClick, disabled) => {
    if (disabled) {
      return;
    }

    return (
      <span className={styles.imageGalleryLeftNav} onClick={onClick}>
        <ArrowLeftIcon />
      </span>
    );
  };

  const renderRightNav = (onClick, disabled) => {
    if (disabled) {
      return;
    }

    return (
      <span className={styles.imageGalleryRightNav} onClick={onClick}>
        <ArrowRightIcon />
      </span>
    );
  };
  const customRender = (item) => {
    return (
      <div>
        <img
          className="image-gallery-image"
          src={item.original}
          alt={item.originalAlt}
          srcSet={item.srcSet}
          sizes={item.sizes}
          title={item.originalTitle}
          width="100%"
          height="100%"
        />
        {item.description && <span className="image-gallery-description">{item.description}</span>}
      </div>
    );
  };
  return (
    <>
      {sticky ? (
        <div ref={galleryEl}>
          <ImageGallery
            renderItem={customRender}
            disableThumbnailScroll
            items={images}
            lazyLoad
            onSlide={onImageGallerySlide}
            renderLeftNav={renderLeftNav}
            renderRightNav={renderRightNav}
            slideInterval={2000}
            stopPropagation
            showPlayButton={false}
            showFullscreenButton={false}
            sizes="width: 100, height: 200"
          />
        </div>
      ) : (
        <ImageGallery
          renderItem={customRender}
          disableThumbnailScroll
          items={images}
          lazyLoad
          onSlide={onImageGallerySlide}
          renderLeftNav={renderLeftNav}
          renderRightNav={renderRightNav}
          slideInterval={2000}
          stopPropagation
          showPlayButton={false}
          showFullscreenButton={false}
          sizes="width: 100, height: 200"
        />
      )}
    </>
  );
};

Gallery.propTypes = {
  images: PropTypes.array,
  sticky: PropTypes.bool,
  onImageGallerySlide: PropTypes.func,
};

export default Gallery;

import React, { useEffect } from "react";
import classNames from "classnames";
import { useIntl } from "react-intl";
import styles from "./Suggestions.module.scss";

const Highlight = ({ text, query }) => {
  let fullText = text;

  const parts = [];

  while (fullText) {
    const pos = fullText.toLowerCase().indexOf(query.toLowerCase());

    if (pos === -1) {
      parts.push(fullText);
      break;
    }

    parts.push(fullText.substr(0, pos));
    parts.push(<b key={Math.random()}>{fullText.substr(pos, query.length)}</b>);
    fullText = fullText.substr(pos + query.length);
  }

  return parts;
};

const Suggestions = React.memo(
  ({ onClose, onSelect, query, selected, suggestions }) => {
    const intl = useIntl();
    useEffect(() => {
      const handleClickOutside = () => {
        if (onClose) {
          onClose();
        }
      };

      window.addEventListener("mousedown", handleClickOutside);

      return () => {
        window.removeEventListener("mousedown", handleClickOutside);
      };
    });

    return (
      <>
        <div
          className={styles.suggestionBox}
          data-testid="suggestionBox"
          tabIndex="0"
        >
          {suggestions &&
            suggestions
              .sort((a, b) => {
                if (a.type === "category" && b.type !== "category") return -1;
                if (a.type !== "category" && b.type === "category") return 1;
                if (a.type === "shop" && b.type !== "shop") return -1;
                if (a.type !== "shop" && b.type === "shop") return 1;
                if (a.type === "brand" && b.type !== "brand") return -1;
                if (a.type !== "brand" && b.type === "brand") return 1;
                if (a.type === "product" && b.type !== "product") return -1;
                if (a.type !== "product" && b.type === "product") return 1;
                return 0;
              })
              .map((suggestion, index, arr) => {
                const type =
                  index > 0 && suggestion.type === arr[index - 1].type
                    ? ""
                    : suggestion.type;
                let translatedType;

                switch (type) {
                  case "category":
                    translatedType =
                      intl.messages["search.suggestionsTags.cat"];
                    break;

                  case "brand":
                    translatedType =
                      intl.messages["search.suggestionsTags.brand"];
                    break;

                  case "shop":
                    translatedType =
                      intl.messages["search.suggestionsTags.shop"];

                    break;

                  case "product":
                    translatedType =
                      intl.messages["search.suggestionsTags.product"];
                    break;

                  default:
                    break;
                }

                return (
                  <div
                    className={classNames(styles.suggestionType, {
                      [styles.selected]: selected === index,
                      [styles.first]: !!type,
                    })}
                    key={index}
                    data-testid={`suggestion${index}`}
                  >
                    <button
                      className={styles.link}
                      onMouseDown={(e) => e.stopPropagation()}
                      onClick={() => {
                        if (onSelect) {
                          onSelect(index);
                        }
                      }}
                    >
                      <span className={styles.query}>
                        <Highlight text={suggestion.text} query={query} />
                      </span>
                      {suggestion.catRoute && suggestion.catRoute.length > 1 && (
                        <>
                          <span className={styles.in}>
                            {" "}
                            in {suggestion.catRoute[0]}
                          </span>
                        </>
                      )}
                    </button>
                    <span className={styles.suggestionName}>
                      {translatedType}
                    </span>
                  </div>
                );
              })}
        </div>
      </>
    );
  }
);

Suggestions.displayName = "Sugestions";

export default Suggestions;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Checkbox.module.scss';

const VALIDATE_VALID = 'valid';
const VALIDATE_INVALID = 'invalid';

const Checkbox = ({ children, classList = {}, validate, disabled = false, ...props }) => {
  const rootStyle = classNames(
    styles.checkboxContainer,
    {
      [styles.disabled]: disabled,
      [styles.valid]: validate === VALIDATE_VALID ? true : false,
      [styles.invalid]: validate === VALIDATE_INVALID ? true : false,
    },
    classList.root
  );
  return (
    <label className={rootStyle} data-testid={props['data-testid']}>
      <input disabled={disabled} type="checkbox" {...props} data-testid={undefined} />
      <span className={classNames(styles.checkboxButton, classList.checkboxButton)}></span>
      {children}
    </label>
  );
};

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
  children: PropTypes.node,
  classList: PropTypes.objectOf(PropTypes.string),
  validate: PropTypes.oneOf([VALIDATE_VALID, VALIDATE_INVALID]),
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  testid: PropTypes.string,
};

export default Checkbox;

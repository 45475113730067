import classNames from "classnames";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { FixedSizeGrid as Grid, FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { useDispatch } from "react-redux";

import Button from "../Button/Button";
import DrawerToMenu, {
  DrawerContent,
  MenuContent,
} from "../DrawerToMenu/DrawerToMenu";
import Checkbox from "../Checkbox/Checkbox";
import TextInput, { ICON_POSITION_LEFT } from "../TextInput/TextInput";

import { ReactComponent as CloseIcon } from "../images/icons/close.svg";
import { ReactComponent as ArrowLeftIcon } from "../images/icons/arrowLeft.svg";
import { ReactComponent as SearchIcon } from "../images/icons/search.svg";

import useSearchApi from "../helpers/useSearchApi";
import { updateFilters } from "../reducers/searchPayloadReducer";

import styles from "./FilterShowAllPopup.module.scss";

const FilterShowAllPopup = ({
  classList,
  filterType,
  placeholder,
  searchPayload,
  trigger,
  items,
  title,
  profile,
  selected,
  setShowingMore,
}) => {
  const dispatch = useDispatch();

  const [cleared, setCleared] = useState(false);
  const [search, setSearch] = useState(null);
  const [allItems, setAllItems] = useState(selected);

  const supportFacets = ["brand", "webshop", "model"];

  const fullPayload = { ...searchPayload };
  fullPayload.facetName = filterType === "webshop" ? "campaign" : filterType;
  delete fullPayload.wpCategory;

  const featuresHeaders = {};
  if (!!profile && !!profile.token) {
    featuresHeaders.Authorization = `Bearer ${profile.token}`;
  }

  const { data: featuresData } = useSearchApi("/facet-full", {
    headers: featuresHeaders,
    method: "POST",
    data: fullPayload,
    shouldRun: supportFacets.includes(filterType),
  });

  if (!featuresData && supportFacets.includes(filterType)) {
    return null;
  }

  const selectItemList = supportFacets.includes(filterType)
    ? featuresData[fullPayload.facetName].buckets
    : items;
  const list = search
    ? selectItemList.filter((item) =>
        item.key.toLowerCase().includes(search.toLowerCase())
      )
    : selectItemList;

  const sortedList = [...list].sort((listItem) => {
    return selected.includes(listItem.key) ? -1 : 0;
  });

  const clear = () => {
    setAllItems([]);
    setCleared(true);
  };

  const addItem = (value) => {
    if (allItems.includes(value)) {
      return setAllItems(allItems.filter((item) => value !== item));
    }

    setAllItems(allItems.concat([value]));
  };

  const Cell = ({ columnIndex, rowIndex, style }) => (
    <div
      className={styles.gridItem}
      style={style}
      key={
        sortedList[rowIndex * 2 + columnIndex].key +
        sortedList[rowIndex * 2 + columnIndex].doc_count
      }
    >
      <Checkbox
        checked={
          cleared
            ? false
            : allItems.includes(sortedList[rowIndex * 2 + columnIndex].key)
        }
        classList={{
          root: classList.checkboxLabel,
          checkboxButton: classList.checkboxButton,
        }}
        onChange={(e) => addItem(e.currentTarget.value)}
        value={sortedList[rowIndex * 2 + columnIndex].key}
      >
        <span className={styles.itemName}>
          {sortedList[rowIndex * 2 + columnIndex].key}
        </span>
        <span className={styles.itemDocs}>
          ({sortedList[rowIndex * 2 + columnIndex].doc_count})
        </span>
      </Checkbox>
    </div>
  );

  const Row = ({ index, style }) => (
    <div
      className={styles.gridItem}
      style={style}
      key={sortedList[index].key + sortedList[index].doc_count}
    >
      <Checkbox
        checked={cleared ? false : allItems.includes(sortedList[index].key)}
        classList={{
          root: classList.checkboxLabel,
          checkboxButton: classList.checkboxButton,
        }}
        onChange={(e) => addItem(e.currentTarget.value)}
        value={sortedList[index].key}
      >
        <span className={styles.itemName}>{sortedList[index].key}</span>
        <span className={styles.itemDocs}>({sortedList[index].doc_count})</span>
      </Checkbox>
    </div>
  );

  return (
    <DrawerToMenu data-testid="filterShowAll" key={placeholder}>
      <DrawerContent
        direction="LeftToRight"
        trigger={trigger}
        onOverlayClick={() => setShowingMore(false)}
        isNested
      >
        <div className={classNames(styles.scroll, "container")}>
          <div className="siab-row">
            <div className={classNames(styles.filterShowAll, "col-12-xs")}>
              <header>
                <FormattedMessage id="common.goBack" defaultMessage="Go back">
                  {(label) => (
                    <button
                      aria-label={label}
                      onClick={() => setShowingMore(false)}
                    >
                      <ArrowLeftIcon />
                    </button>
                  )}
                </FormattedMessage>
                <h3>{title}</h3>
                <FormattedMessage
                  id="common.menu.closeMenu"
                  defaultMessage="Close"
                >
                  {(label) => (
                    <button
                      aria-label={label}
                      onClick={() => setShowingMore(false)}
                    >
                      <CloseIcon />
                    </button>
                  )}
                </FormattedMessage>
              </header>
              <TextInput
                classList={{
                  root: styles.searchBoxContainer,
                  icon: styles.searchIconContainer,
                  input: styles.searchBox,
                }}
                iconPosition={ICON_POSITION_LEFT}
                Icon={<SearchIcon className={styles.searchIcon} />}
                onInput={(e) => setSearch(e.currentTarget.value)}
                placeholder={placeholder}
                type="search"
              />
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    className={styles.root}
                    height={height}
                    itemCount={sortedList.length}
                    itemSize={35}
                    width={width}
                  >
                    {Row}
                  </List>
                )}
              </AutoSizer>
              <footer className={styles.footer}>
                <Button
                  inverted
                  classList={{ root: styles.button }}
                  onClick={() => clear()}
                >
                  <FormattedMessage
                    defaultMessage="Clear selection"
                    id="common.clearSelection"
                  />
                </Button>
                <Button
                  variation="secondary"
                  classList={{ root: styles.button }}
                  onClick={() => {
                    dispatch(
                      updateFilters({ filter: filterType, value: allItems })
                    );
                  }}
                >
                  <FormattedMessage defaultMessage="Apply" id="common.apply" />
                </Button>
              </footer>
            </div>
          </div>
        </div>
      </DrawerContent>
      <MenuContent classList={{ root: styles.menu }} shouldBeOpen={true}>
        <div className={classNames(styles.filterShowAllMenu)}>
          <header>
            <h3>{title}</h3>
            <FormattedMessage id="common.menu.closeMenu" defaultMessage="Close">
              {(label) => (
                <button
                  aria-label={label}
                  onClick={() => setShowingMore(false)}
                >
                  <CloseIcon />
                </button>
              )}
            </FormattedMessage>
          </header>
          <TextInput
            classList={{
              root: styles.searchBoxContainer,
              icon: styles.searchIconContainer,
              input: styles.searchBox,
            }}
            iconPosition={ICON_POSITION_LEFT}
            Icon={<SearchIcon className={styles.searchIcon} />}
            onInput={(e) => setSearch(e.currentTarget.value)}
            placeholder={placeholder}
            type="search"
          />
          <div className={styles.scrollContainer}>
            <Grid
              className={styles.root}
              columnCount={sortedList.length > 1 ? 2 : 1}
              columnWidth={230}
              height={350}
              rowCount={sortedList.length / 2}
              rowHeight={25}
              width={485}
            >
              {Cell}
            </Grid>
          </div>
          <footer className={styles.footer}>
            <Button
              inverted
              classList={{ root: styles.button }}
              onClick={() => clear()}
            >
              <FormattedMessage
                defaultMessage="Clear selection"
                id="common.clearSelection"
              />
            </Button>
            <Button
              variation="secondary"
              classList={{ root: styles.button }}
              onClick={() => {
                dispatch(
                  updateFilters({ filter: filterType, value: allItems })
                );
              }}
            >
              <FormattedMessage defaultMessage="Apply" id="common.apply" />
            </Button>
          </footer>
        </div>
      </MenuContent>
    </DrawerToMenu>
  );
};

export default FilterShowAllPopup;

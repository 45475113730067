import anime from "animejs/lib/anime.es.js";

let animation = null;
let scrollAnimation = null;

export const scrollToWithEasing = (to, duration) => {
  if (typeof scrollAnimation === "function") {
    scrollAnimation.remove();
  }

  const scroll = {
    y: window.pageYOffset,
  };

  const scrollAnim = anime({
    targets: scroll,
    y: to,
    duration: duration,
    easing: "easeInOutQuad",
    update: () => {
      window.scroll(0, scroll.y);
    },
    complete: () => {
      scrollAnimation = null;
    },
  });

  scrollAnimation = scrollAnim;
};

export const animateScroll = (productCardNode) => {
  // const top = getNodePageY(productCardNode);
  // const header = headerStyles.stickyHeader;
  // const headerHeight = document.querySelector(`.${header}`).clientHeight;
  // scrollToWithEasing(top - headerHeight - 30, 350);
};

export const openCardAnimation = (card, cardFullHeight, isAllShopsBtnShown) => {
  const marginAnimation = isAllShopsBtnShown
    ? {
        marginBottom: 64,
      }
    : null;
  animation = anime({
    targets: card,
    maxHeight: [0, cardFullHeight],
    duration: 350,
    easing: "easeInOutQuad",
    ...marginAnimation,
  });
};

export const closeCardAnimation = (card, cardFullHeight, onEnd) => {
  anime.remove(card);

  animation = anime({
    targets: card,
    maxHeight: [cardFullHeight, 0],
    marginBottom: {
      delay: 250,
      duration: 100,
      value: 0,
    },
    opacity: {
      delay: 250,
      duration: 100,
      value: 0,
    },
    duration: 350,
    easing: "easeInOutQuad",
    complete: () => {
      onEnd();
    },
  });
};

export const stopCardAnimation = () => animation && animation.pause();

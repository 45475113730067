import slugify from './slugify';

/**
 * When provided with a full URL it returns the hostname (without subdomains).
 * E.g.
 * Given a "https://www.example.com/a/b/c/" returns "example.com"
 * In case of any error, logs it to console.error() and returns an empty string.
 *
 * @function generateShopUrl
 * @param {string} shopWebsite
 * @returns {string} Returns the hostname of shopWebsite
 */
export default function generateShopUrl(shopWebsite = '') {
  try {
    const shopWebsiteAfterProtocol = shopWebsite.split('://')[1];
    const shopWebsiteNextSlash = shopWebsiteAfterProtocol.indexOf('/');
    const shopHostname = shopWebsiteAfterProtocol.substr(
      0,
      shopWebsiteNextSlash !== -1 ? shopWebsiteNextSlash : undefined
    );
    return slugify(
      shopHostname
        .split('.')
        .slice(-2)
        .join('.')
    );
  } catch (e) {
    console.error(e);
    return '';
  }
}

import { useEffect, useRef, useState } from "react";
import axios from "axios";

/**
 * @typedef UseFetchResponse
 * @property {mixed} data Data obtained from the request. Initialized as `null`
 * @property {Error} error Error caught while making the request.
 * @property {boolean} loading Flag determining if the request is still happening or not. Initializes as true.
 */

/**
 * Executes a request to a given URL.
 * It can receive an `options` object that is passed to the `axios` call.
 * It can be used to, for example, set the method to POST or send a body
 * or define headers.
 *
 * @function useFetch
 * @param {string} url URL to be fetched
 * @param {object} [options] Options to be passed to `axios`.
 * @return {UseFetchResponse}
 */
export default function useFetch(url, options = {}) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const source = useRef(null);

  const optionsString = JSON.stringify(options);

  function doFetch() {
    source.current = axios.CancelToken.source();
    setLoading(true);

    axios(url, {
      cancelToken: source.current.token,
      ...options,
    })
      .then((res) => {
        setData(res.data);
        setError(null);
        setLoading(false);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          setData(null);
          setError(e);
          setLoading(false);
        }
      });
  }

  useEffect(() => {
    if (typeof options.shouldRun === "boolean" && !options.shouldRun) {
      setLoading(false);
      return;
    }

    delete options.shouldRun;

    doFetch();
    return () => {
      source.current.cancel(`Unmounting fetch for ${url}`);
    };
  }, [url, optionsString]); // eslint-disable-line react-hooks/exhaustive-deps

  return { data, error, loading };
}

import sorter from 'apparel-sorter';

/**
 * @param sizes - array of sizes
 */
export default function(sizes) {
  // split sizes to the following groups
  // - numeric (like, 32, 33, 34 etc)
  // - literal and mixed (XL, XXL, 3XS, 2XL)
  // - others

  const numeric = [];
  const literal = [];
  const others = [];
  sizes.forEach((size) => {
    if (/^(\d*?)x*(s|m|l)$/i.test(size)) {
      literal.push(size);
      // eslint-disable-next-line
    } else if (/^[\d\.,]+$/.test(size) && !isNaN(parseInt(size, 10))) {
      numeric.push(size);
    } else {
      others.push(size);
    }
  });

  numeric.sort((first, second) => parseInt(first, 10) - parseInt(second, 10));
  const sortedLiterals = sorter.sort(literal);
  const sortedOthers = sorter.sort(others);
  return numeric.concat(sortedLiterals).concat(sortedOthers);
}

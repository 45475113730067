import useFetch from './useFetch';

/**
 * @typedef UseSearchApiResponse
 * @property {mixed} data Data obtained from the request. Initialized as `null`
 * @property {Error} error Error caught while making the request.
 * @property {boolean} loading Flag determining if the request is still happening or not. Initializes as true.
 */

/**
 * Makes a request to our Search API.
 *
 * @function useSearchApi
 * @param {string} path
 * @param {object} [options]
 * @returns {UseSearchApiResponse}
 */
const useSearchApi = (path, options = {}) => {
  options.withCredentials = typeof options.withCredentials !== 'undefined' ? options.withCredentials : true;
  return useFetch(`${process.env.REACT_APP_SEARCH_API_URL}${path}`, options);
};

export default useSearchApi;

import React from 'react';

import styles from './FilterPlaceholder.module.scss';

const FilterPlaceholder = React.memo(() => {
  return (
    <div className={styles.root}>
      <div className={styles.categories}>
        <p className={styles.title}></p>
        <p className={styles.category}></p>
        <p className={styles.category}></p>
        <p className={styles.category}></p>
        <p className={styles.category}></p>
        <p className={styles.category}></p>
        <p className={styles.category}></p>
        <p className={styles.category}></p>
        <p className={styles.category}></p>
        <p className={styles.category}></p>
        <p className={styles.category}></p>
        <p className={styles.category}></p>
      </div>
      <div className={styles.price}>
        <p className={styles.title}></p>
        <p className={styles.priceGraph}></p>
        <p className={styles.item}></p>
        <p className={styles.item}></p>
        <p className={styles.item}></p>
        <p className={styles.item}></p>
        <p className={styles.item}></p>
        <p className={styles.item}></p>
        <p className={styles.priceBox}></p>
      </div>
      <div className={styles.filters}>
        <p className={styles.searchBox}></p>
        <p className={styles.title}></p>
        <p className={styles.item}></p>
        <p className={styles.item}></p>
        <p className={styles.item}></p>
        <p className={styles.item}></p>
        <p className={styles.item}></p>
        <p className={styles.item}></p>
      </div>
      <div className={styles.filters}>
        <p className={styles.searchBox}></p>
        <p className={styles.title}></p>
        <p className={styles.item}></p>
        <p className={styles.item}></p>
        <p className={styles.item}></p>
        <p className={styles.item}></p>
        <p className={styles.item}></p>
        <p className={styles.item}></p>
      </div>
      <div className={styles.filters}>
        <p className={styles.searchBox}></p>
        <p className={styles.title}></p>
        <p className={styles.item}></p>
        <p className={styles.item}></p>
        <p className={styles.item}></p>
        <p className={styles.item}></p>
        <p className={styles.item}></p>
        <p className={styles.item}></p>
      </div>
    </div>
  );
});

FilterPlaceholder.displayName = 'FilterPlaceholder';

export default FilterPlaceholder;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { FormattedMessage, FormattedHTMLMessage, useIntl } from "react-intl";
import parse from "html-react-parser";

import { DRAWER_DIRECTION_LTR } from '../Drawer/Drawer';
import NoResultsPage from "../NoResultsPage/NoResultsPage";
import ProductsList from "../ProductsList/ProductsList";
import FilterPlaceholder from "../FilterPlaceholder/FilterPlaceholder";
import FilterCategories from "../FilterCategories/FilterCategories";
import FilterPrice from "../FilterPrice/FilterPrice";
import FilterSearchable from "../FilterSearchable/FilterSearchable";
import FilterGrid from "../FilterGrid/FilterGrid";
import Pagination from "../Pagination/Pagination";
import PageSizeDropdown from "../PageSizeDropdown/PageSizeDropdown";
// import FiltersHomeDrawer from '../FiltersHomeDrawer/FiltersHomeDrawer';

import useSearchApi from "../helpers/useSearchApi";
import getSortedColors from "../helpers/getSortedColors";
import getSortedSizes from "../helpers/getSortedSizes";
import slugify from "../helpers/slugify";

import { ReactComponent as ChevronDownIcon } from "../images/icons/chevron_down.svg";
import { ReactComponent as ChevronUpIcon } from "../images/icons/chevron_up.svg";
import { ReactComponent as ViewTilesIcon } from "../images/icons/tiles.svg";
import { ReactComponent as ViewTwoColumnsIcon } from "../images/icons/2col.svg";
import { ReactComponent as FiltersIcon } from "../images/icons/filters.svg";

import {
  reset,
  toggleFilters,
  updateFilters,
  updateSort,
  updateWpCategory,
  SORTING_OPTIONS_NEW,
  SORTING_OPTIONS_PRICE_ASC,
  SORTING_OPTIONS_PRICE_DESC,
  SORTING_OPTIONS_RELEVANCE,
} from "../reducers/searchPayloadReducer";

import styles from "./SearchResults.module.scss";
import Button from "../Button/Button";

const SearchResults = () => {
  const query = useSelector((state) => state.searchPayload.payload.q);
  const [loadFeatures, setLoadFeatures] = useState(false);
  const [featureFilter, setFeatureFilter] = useState("");
  const searchPayload = useSelector((state) => state.searchPayload);
  const config = useSelector((state) => state.config);
  const [viewOption, setViewOption] = useState("tiles");
  const dispatch = useDispatch();
  const intl = useIntl();

  const { data: searchData } = useSearchApi("/search", {
    headers: {},
    method: "POST",
    data: searchPayload,
  });

  const { data: featuresData } = useSearchApi("/facets", {
    headers: {},
    method: "POST",
    data: searchPayload,
  });

  const categoryFilter =
    useSelector((state) => state.searchPayload.payload.filters?.category) ?? [];
  const price =
    useSelector((state) => state.searchPayload.payload.filters?.price) ?? [];
  const brandsFilter =
    useSelector((state) => state.searchPayload.payload.filters?.brand) ?? [];
  const colorsFilter =
    useSelector((state) => state.searchPayload.payload.filters?.color) ?? [];
  const gendersFilter =
    useSelector((state) => state.searchPayload.payload.filters?.gender) ?? [];
  const modelsFilter =
    useSelector((state) => state.searchPayload.payload.filters?.model) ?? [];
  const shopsFilter =
    useSelector((state) => state.searchPayload.payload.filters?.campaign) ?? [];
  const sizesFilter = useSelector(
    (state) => state.searchPayload.payload.filters?.size
  );
  const sortingOption = useSelector(
    (state) => state.searchPayload.payload.sort
  );
  const page = useSelector((state) => state.searchPayload.payload.page.current);
  const maxProductsPerPage = useSelector(
    (state) => state.searchPayload.payload.page.size
  );

  const numberTotalProducts =
    searchData && searchData.hits.total && searchData.hits.total.value;
  const startingProduct = page * maxProductsPerPage + 1;
  const endingProduct =
    maxProductsPerPage < numberTotalProducts
      ? (page + 1) * maxProductsPerPage
      : numberTotalProducts;

  const otherFeatures = !featuresData
    ? []
    : Object.keys(featuresData)
        .filter(
          (prop) =>
            prop.startsWith("feature::") &&
            featuresData[prop]?.nested_features?.filter_feature?.feature?.buckets?.length
        )
        .sort((a, b) =>
          featuresData[a].wpIndex < featuresData[b].wpIndex ? -1 : 1
        )
        .map((prop) => featuresData[prop]);

  const isFilterEnabled = (name) => {
    if (!!categoryFilter?.length > 0) {
      return true;
    }

    return config.defaults.facets.default.includes(name);
  };
  const removeFilter = (item, value) => {
    switch (item[0]) {
      case "category":
        const numberInArray =
          item[1].length > 1 ? item[1].indexOf(value) - 1 : 0;
        const allCategories = featuresData?.category?.buckets || [];
        const root = allCategories.find((cat) => cat.key === item[1][0]);

        let category = [];
        let wpCategory = "";

        if (item[1].indexOf(value) === 0) {
          category = undefined;
          wpCategory = undefined;
        }

        if (item[1].indexOf(value) === 1) {
          category.push(item[1][0]);
          wpCategory = root.slug;
        }
        if (item[1].indexOf(value) === 2) {
          category.push(item[1][0]);
          category.push(item[1][1]);
          wpCategory = root.subcategory.buckets.find(
            (subCat) => subCat.key === item[1][1]
          ).slug;
        }

        return dispatch(
          updateWpCategory({
            wpCategory,
            category,
          })
        );
      case "price":
        return dispatch(
          updateFilters({
            filter: "price",
            value: undefined,
          })
        );
      default:
        return dispatch(
          toggleFilters({
            filter: item[0],
            value: [value],
          })
        );
    }
  };

  const filters = Object.entries(searchPayload.payload.filters)
    .map((item) => {
      if (!!item[1]) return item;
    })
    .filter(Boolean);

  const filterItems = filters.map((item, index) => {
    let priceRange = [];

    if (item[0] === "price") {
      if (item[1][1]) {
        priceRange.push(item[1][0]);
        priceRange.push(item[1][1]);
      } else {
        priceRange.push(item[1][0]);
      }

      return (
        <li key={item[0] + index}>
          <a className={styles.activeFilterPill} onClick={() => removeFilter(item, priceRange)}>
            <span className={styles.activeFilterPillText}>
              {priceRange.length > 1
                ? `${priceRange[0]} - ${priceRange[1]}`
                : `${priceRange[0]}+`}
            </span>
            <span
              className={styles.activeFilterPillCross}
            >
              x
            </span>
          </a>
        </li>
      );
    }

    return item[1].map((value) => {
      return (
        <li>
          <a className={styles.activeFilterPill}>
            <span className={styles.activeFilterPillText}>{value}</span>
            <span
              className={styles.activeFilterPillCross}
              onClick={() => removeFilter(item, value)}
            >
              x
            </span>
          </a>
        </li>
      );
    });
  });

  const handleAccordionClick = (e) => {
    const parent = e.currentTarget.parentNode;
    parent.classList.toggle(styles.filtersAccordionOpen);
    parent.focus();

    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(() => {
        parent.scrollTo(0, 0);
      });
    });
  };

  return (
    <>
      <div className={styles.root}>
        {filters.length > 0 && (
          <div className={styles.filterPills}>
            <div className={classnames(styles.activeFiltersContainer)}>
              <ul className={classnames(styles.activeFilters)}>
                {filterItems}
              </ul>
              <div className={styles.clearAllFilters}>
                <a
                  className={styles.clearAllFiltersCross}
                  onClick={() => dispatch(reset())}
                >
                  <span className={styles.clearAllFiltersCrossIcon}>×</span>
                  <span className={styles.clearAllFiltersCrossText}>
                    <FormattedMessage
                      defaultMessage="Clear all"
                      id="common.clearAll"
                    />
                  </span>
                </a>
              </div>
            </div>
          </div>
        )}
        {searchData && searchData.hits.hits.length === 0 && (
          <NoResultsPage searchTerm={query} />
        )}
        {(!searchData || searchData?.hits?.hits?.length > 0) && (
          <>
            <aside className={classnames(styles.filters)}>
              {featuresData === null && <FilterPlaceholder />}
              {featuresData !== null && (
                <>
                  <div
                    className={classnames(
                      styles.filtersAccordion,
                      styles.filtersAccordionOpen
                    )}
                  >
                    <span
                      className={classnames(
                        styles.filtersTitle,
                        styles.filterPriceTitle
                      )}
                      onClick={handleAccordionClick}
                    >
                      <FormattedMessage
                        defaultMessage="Categories"
                        id="filters.category.title"
                      />
                    </span>
                    {isFilterEnabled("category") && (
                      <FilterCategories
                        categories={featuresData?.category?.buckets}
                        filter={categoryFilter}
                      />
                    )}
                  </div>
                  <div
                    className={classnames(
                      styles.filtersAccordion,
                      styles.filtersAccordionOpen
                    )}
                  >
                    <span
                      className={classnames(
                        styles.filtersTitle,
                        styles.filterPriceTitle
                      )}
                      onClick={handleAccordionClick}
                    >
                      <FormattedMessage
                        defaultMessage="Price"
                        id="filters.price.title"
                      />
                    </span>
                    {featuresData !== null && isFilterEnabled("price") && (
                      <FilterPrice
                        classList={{
                          checkboxLabel: styles.filtersCheckboxLabel,
                          checkboxButton: styles.filtersCheckboxButton,
                        }}
                        currencySymbol={process.env.REACT_APP_WEBSITE_CURRENCY}
                        items={featuresData?.price_ranges?.buckets}
                        key={`${price[0]}_${price[1]}`}
                        ranges={featuresData?.price_ranges?.ranges?.buckets}
                        selected={[price[0], price[1]]}
                        onChange={({ hasBeenRemoved, value }) => {
                          if (hasBeenRemoved) {
                            dispatch(
                              updateFilters({
                                filter: "price",
                                value: undefined,
                              })
                            );
                          } else {
                            const [price, priceTo] = value;
                            dispatch(
                              updateFilters({
                                filter: "price",
                                value: [price, priceTo ? priceTo : undefined],
                              })
                            );
                          }
                        }}
                      />
                    )}
                  </div>
                  { isFilterEnabled("brand") &&
                    !!featuresData?.brand?.buckets?.length && (
                      <div
                        className={classnames(
                          styles.filtersAccordion,
                          styles.filtersAccordionOpen
                        )}
                      >
                        <span
                          className={styles.filtersTitle}
                          onClick={handleAccordionClick}
                        >
                          <FormattedMessage
                            defaultMessage="Brands"
                            id="filters.brand.title"
                          />
                        </span>
                        <FormattedMessage
                          defaultMessage="Search brand..."
                          id="filters.brand.searchPrompt"
                        >
                          {(placeholder) => (
                            <>
                              <FilterSearchable
                                classList={{
                                  checkboxLabel: styles.filtersCheckboxLabel,
                                  checkboxButton: styles.filtersCheckboxButton,
                                }}
                                items={featuresData?.brand?.buckets}
                                hasMore={featuresData?.brand?.hasMore}
                                onChange={({ value }) => {
                                  const newFilter = [].concat(value);

                                  dispatch(
                                    toggleFilters({
                                      filter: "brand",
                                      value: newFilter,
                                    })
                                  );
                                }}
                                title={intl.messages["filters.brand.all"]}
                                placeholder={placeholder}
                                selected={brandsFilter}
                                searchPayload={searchPayload}
                                type="brand"
                              />
                            </>
                          )}
                        </FormattedMessage>
                      </div>
                    )}
                  { isFilterEnabled("campaign") &&
                    !!featuresData?.campaign?.buckets?.length && (
                      <div
                        className={classnames(
                          styles.filtersAccordion,
                          styles.filtersAccordionOpen
                        )}
                      >
                        <span
                          className={styles.filtersTitle}
                          onClick={handleAccordionClick}
                        >
                          <FormattedMessage
                            defaultMessage="Shops"
                            id="filters.campaign.title"
                          />
                        </span>
                        <FormattedMessage
                          defaultMessage="Search shop..."
                          id="filters.campaign.searchPrompt"
                        >
                          {(placeholder) => (
                            <>
                              <FilterSearchable
                                classList={{
                                  checkboxLabel: styles.filtersCheckboxLabel,
                                  checkboxButton: styles.filtersCheckboxButton,
                                }}
                                items={featuresData?.campaign?.buckets}
                                hasMore={featuresData?.campaign?.hasMore}
                                onChange={({ hasBeenRemoved, value }) => {
                                  const newFilter = [].concat(value);

                                  dispatch(
                                    toggleFilters({
                                      filter: "campaign",
                                      value: newFilter,
                                    })
                                  );
                                }}
                                title={intl.messages["filters.campaign.all"]}
                                placeholder={placeholder}
                                selected={shopsFilter}
                                searchPayload={searchPayload}
                                type="campaign"
                              />
                            </>
                          )}
                        </FormattedMessage>
                      </div>
                    )}
                  { isFilterEnabled("color") &&
                    !!featuresData?.color?.buckets?.length && (
                      <div
                        className={classnames(
                          styles.filtersAccordion,
                          styles.filtersAccordionOpen
                        )}
                      >
                        <span
                          className={styles.filtersTitle}
                          onClick={handleAccordionClick}
                        >
                          <FormattedMessage
                            defaultMessage="Color"
                            id="filters.color.title"
                          />
                        </span>
                        <FilterGrid
                          onChange={({ hasBeenRemoved, value }) => {
                            const newFilter = hasBeenRemoved
                              ? colorsFilter.filter((item) => item !== value)
                              : colorsFilter.concat(value);

                            dispatch(
                              toggleFilters({
                                filter: "color",
                                value: newFilter,
                              })
                            );
                          }}
                          renderAsColors
                          selected={colorsFilter}
                          values={
                            featuresData?.color?.buckets &&
                            getSortedColors(featuresData?.color?.buckets, {
                              colorMap: config.defaults.facets.sortValues.color,
                            })
                          }
                        />
                      </div>
                    )}
                  { isFilterEnabled("size") &&
                    !!featuresData?.size?.buckets?.length && (
                      <div
                        className={classnames(
                          styles.filtersAccordion,
                          styles.filtersAccordionOpen
                        )}
                      >
                        <span
                          className={styles.filtersTitle}
                          onClick={handleAccordionClick}
                        >
                          <FormattedMessage
                            defaultMessage="Size"
                            id="filters.size.title"
                          />
                        </span>
                        <FilterGrid
                          onChange={({ hasBeenRemoved, value }) => {
                            const newFilter = hasBeenRemoved
                              ? sizesFilter.filter((item) => item !== value)
                              : sizesFilter.concat(value);

                            dispatch(
                              toggleFilters({
                                filter: "size",
                                value: newFilter,
                              })
                            );
                          }}
                          selected={sizesFilter}
                          values={
                            featuresData?.size?.buckets && getSortedSizes(featuresData.size.buckets)
                          }
                        />
                      </div>
                    )}
                  { isFilterEnabled("gender") &&
                    !!featuresData?.gender?.buckets?.length && (
                      <div
                        className={classnames(
                          styles.filtersAccordion,
                          styles.filtersAccordionOpen
                        )}
                      >
                        <span
                          className={styles.filtersTitle}
                          onClick={handleAccordionClick}
                        >
                          <FormattedMessage
                            defaultMessage="Gender"
                            id="filters.gender.title"
                          />
                        </span>
                        <FilterSearchable
                          classList={{
                            checkboxLabel: styles.filtersCheckboxLabel,
                            checkboxButton: styles.filtersCheckboxButton,
                          }}
                          items={featuresData?.gender?.buckets}
                          hasMore={featuresData?.gender?.hasMore}
                          onChange={({ hasBeenRemoved, value }) => {
                            const newFilter = [].concat(value);

                            dispatch(
                              toggleFilters({
                                filter: "gender",
                                value: newFilter,
                              })
                            );
                          }}
                          type="gender"
                          title={intl.messages["filters.gender.title"]}
                          selected={gendersFilter}
                        />
                      </div>
                    )}
                  { isFilterEnabled("model") &&
                    !!featuresData?.model?.buckets?.length && (
                      <div
                        className={classnames(
                          styles.filtersAccordion,
                          styles.filtersAccordionOpen
                        )}
                      >
                        <span
                          className={styles.filtersTitle}
                          onClick={handleAccordionClick}
                        >
                          <FormattedMessage
                            defaultMessage="Model"
                            id="filters.model.title"
                          />
                        </span>
                        <FilterSearchable
                          classList={{
                            checkboxLabel: styles.filtersCheckboxLabel,
                            checkboxButton: styles.filtersCheckboxButton,
                          }}
                          items={featuresData?.model?.buckets}
                          hasMore={featuresData.model?.hasMore}
                          onChange={({ hasBeenRemoved, value }) => {
                            const newFilter = [].concat(value);

                            dispatch(
                              toggleFilters({
                                filter: "model",
                                value: newFilter,
                              })
                            );
                          }}
                          placeholder={
                            intl.messages["filters.model.searchPrompt"]
                          }
                          title={intl.messages["filters.model.all"]}
                          selected={modelsFilter}
                          searchPayload={searchPayload}
                          type="model"
                        />
                      </div>
                    )}
                  {featuresData !== null &&
                    otherFeatures.map((feature) => {
                      const featureValues =
                        feature.nested_features.filter_feature.feature
                          .buckets[0].values.buckets;
                      const featuresFilterItem = otherFeatures.find(
                        (item) => item.key === feature.title
                      );
                      const featureFilter = featuresFilterItem
                        ? featuresFilterItem
                        : { key: feature.title, values: [] };
                      const featureTitle =
                        feature.title ||
                        feature.nested_features.filter_feature.feature
                          .buckets[0].key;
                      const otherFilter =
                        searchPayload.payload.filters[featureTitle];

                      return (
                        <React.Fragment
                          key={`filters-${slugify(featureTitle)}-radio`}
                        >
                          <div
                            className={classnames(styles.filtersAccordion, {
                              [styles.filtersAccordionOpen]: feature.open,
                            })}
                          >
                            <span
                              className={styles.filtersTitle}
                              onClick={handleAccordionClick}
                            >
                              {featureTitle}
                            </span>
                            <FormattedMessage
                              defaultMessage="Search"
                              id="common.search"
                            >
                              {(placeholder) => (
                                <>
                                  <FilterSearchable
                                    classList={{
                                      checkboxLabel:
                                        styles.filtersCheckboxLabel,
                                      checkboxButton:
                                        styles.filtersCheckboxButton,
                                    }}
                                    items={featureValues}
                                    onChange={({ hasBeenRemoved, value }) => {
                                      const newFilter = [].concat(value);

                                      dispatch(
                                        toggleFilters({
                                          filter: featureTitle,
                                          value: newFilter,
                                        })
                                      );
                                    }}
                                    title={`${intl.messages["common.brands.all"]} ${featureTitle}`}
                                    placeholder={placeholder}
                                    selected={otherFilter}
                                    searchPayload={searchPayload}
                                    type={featureTitle}
                                  />
                                </>
                              )}
                            </FormattedMessage>
                          </div>
                        </React.Fragment>
                      );
                    })}
                </>
              )}
            </aside>
            <main>
              {searchData && searchData.hits.hits.length > 0 && (
                <>
                  {query && (
                    <h1 className={classnames(styles.searchTermHeading)}>
                      <FormattedMessage defaultMessage="Buy" id="common.buy">
                        {(type) => (
                          <FormattedHTMLMessage
                            defaultMessage="{type} {term}"
                            id="search.header.buy"
                            values={{ category: type, term: query }}
                          />
                        )}
                      </FormattedMessage>
                    </h1>
                  )}
                  {!query && categoryFilter.length > 0 && (
                    <h1 className={classnames(styles.searchTermHeading)}>
                      <FormattedMessage defaultMessage="Buy" id="common.buy">
                        {(type) => (
                          <FormattedHTMLMessage
                            defaultMessage="{type} {term}"
                            id="search.header.buy"
                            values={{
                              category: type,
                              term: categoryFilter[categoryFilter.length - 1],
                            }}
                          />
                        )}
                      </FormattedMessage>
                    </h1>
                  )}
                  <div className={styles.rowItems}>
                    <div className={classnames(styles.resultsCounter)}>
                      <FormattedHTMLMessage
                        defaultMessage="{numberResults, plural, one {<b>#</b> result} other {<b>#</b> results}}"
                        id="search.resultsCounter"
                        values={{ numberResults: numberTotalProducts }}
                      />
                    </div>
                    <div className={styles.divider}></div>
					{/*
					@TODO, add this back once filter functionality is restored
                    <a className={classnames(styles.filtersBtnContainer)}>
                      <Button
                        classList={{ root: styles.filtersBtn }}
                        type={"primary"}
                        onClick={() => setLoadFeatures(loadFeatures + 1)}
                      >
                        <FiltersIcon className={styles.filtersIcon} />
                        <FormattedMessage
                          defaultMessage="Filters"
                          id="filters.showFilters"
                        />
                      </Button>
                    </a>
					*/}
                    <div className={styles.sortingAndView}>
                      <ul className={classnames(styles.sortingOptions)}>
                        <li>
                          <a
                            className={classnames(styles.sortingOption, {
                              [styles.sortingOptionSelected]:
                                sortingOption.by === SORTING_OPTIONS_RELEVANCE,
                            })}
                            onClick={() =>
                              dispatch(
                                updateSort({ sort: SORTING_OPTIONS_RELEVANCE })
                              )
                            }
                          >
                            <FormattedMessage
                              defaultMessage="Relevance"
                              id="sortOrders.relevance"
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            className={classnames(styles.sortingOption, {
                              [styles.sortingOptionSelected]: [
                                SORTING_OPTIONS_PRICE_ASC,
                                SORTING_OPTIONS_PRICE_DESC,
                              ].includes(sortingOption.by),
                            })}
                            onClick={() =>
                              dispatch(
                                updateSort({
                                  sort:
                                    sortingOption.by +
                                      sortingOption.direction !==
                                    SORTING_OPTIONS_PRICE_ASC
                                      ? SORTING_OPTIONS_PRICE_ASC
                                      : SORTING_OPTIONS_PRICE_DESC,
                                })
                              )
                            }
                          >
                            <FormattedMessage
                              defaultMessage="Price"
                              id="sortOrders.price"
                            />
                            {[
                              SORTING_OPTIONS_PRICE_ASC,
                              SORTING_OPTIONS_PRICE_DESC,
                            ].includes(
                              sortingOption.by + sortingOption.direction
                            ) ? (
                              sortingOption.by + sortingOption.direction ===
                              SORTING_OPTIONS_PRICE_ASC ? (
                                <ChevronDownIcon
                                  className={styles.sortingOptionDirectionIcon}
                                />
                              ) : (
                                <ChevronUpIcon
                                  className={styles.sortingOptionDirectionIcon}
                                />
                              )
                            ) : null}
                          </a>
                        </li>
                        <li>
                          <a
                            className={classnames(styles.sortingOption, {
                              [styles.sortingOptionSelected]:
                                sortingOption === SORTING_OPTIONS_NEW,
                            })}
                            onClick={() =>
                              dispatch(
                                updateSort({ sort: SORTING_OPTIONS_NEW })
                              )
                            }
                          >
                            <FormattedMessage
                              defaultMessage="New"
                              id="sortOrders.new"
                            />
                          </a>
                        </li>
                      </ul>
                      <ul className={classnames(styles.viewOptions)}>
                        <li>
                          <a
                            aria-label="Tiles view"
                            className={classnames(styles.viewOption, {
                              [styles.viewOptionSelected]:
                                viewOption === "tiles",
                            })}
                            onClick={() => setViewOption("tiles")}
                          >
                            <ViewTilesIcon />
                          </a>
                        </li>
                        <li>
                          <a
                            aria-label="Column view"
                            className={classnames(styles.viewOption, {
                              [styles.viewOptionSelected]:
                                viewOption === "columns",
                            })}
                            onClick={() => setViewOption("columns")}
                          >
                            <ViewTwoColumnsIcon />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <ProductsList
                    searchData={searchData}
                    viewOption={viewOption}
                  />
                  <div
                    className={classnames(styles.paginationAndSummaryContainer)}
                  >
                    <div className={classnames(styles.summary)}>
                      <FormattedHTMLMessage
                        defaultMessage="<b>{startingProductNumber}</b> - {endingProductNumber} from {totalProductNumber}"
                        id="pagination.listSummary"
                        values={{
                          startingProductNumber: startingProduct,
                          endingProductNumber: endingProduct,
                          totalProductNumber: numberTotalProducts,
                        }}
                      >
                        {(text) => parse(text)}
                      </FormattedHTMLMessage>
                    </div>
                    <div className={classnames(styles.summary)}>
                      <PageSizeDropdown selected={maxProductsPerPage} />
                    </div>
                    {searchData &&
                      searchData.hits.total &&
                      searchData.hits.total.value > 0 && (
                        <Pagination
                          classList={{
                            root: classnames(styles.pagination),
                          }}
                          currentPage={parseInt(page, 10)}
                          totalPages={Math.ceil(
                            (numberTotalProducts > 10000
                              ? 10000
                              : numberTotalProducts) / maxProductsPerPage
                          )}
                        />
                      )}
                  </div>
                </>
              )}
            </main>
          </>
        )}
      </div>
      {/* {featuresData && loadFeatures && (
        <FiltersHomeDrawer
          brands={featuresData.brand}
          categories={featuresData.category}
          colors={featuresData.color.buckets}
          direction={DRAWER_DIRECTION_LTR}
          searchPayload={searchPayload}
          genders={featuresData.gender.buckets}
          models={featuresData.model}
          priceRanges={featuresData.price_ranges.buckets}
          prices={featuresData.price.buckets}
          otherFeatures={otherFeatures}
          shops={featuresData.campaign}
          sizes={featuresData.size.buckets}
          showFilter={setLoadFeatures}
          trigger="filters"
        />
      )} */}
    </>
  );
};

SearchResults.displayName = "SearchResults";
export default SearchResults;

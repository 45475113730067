import { createSlice } from "@reduxjs/toolkit";

export const DEFAULT_NUMBER_PRODUCTS_PER_PAGE = 30;
export const SORTING_OPTIONS_NEW = "new";
export const SORTING_OPTIONS_POPULAR_ASC = "popularasc";
export const SORTING_OPTIONS_POPULAR_DESC = "populardesc";
export const SORTING_OPTIONS_PRICE_ASC = "priceasc";
export const SORTING_OPTIONS_PRICE_DESC = "pricedesc";
export const SORTING_OPTIONS_RELEVANCE = "relevance";

const initialState = {
  payload: {
    filters: {
      brand: undefined,
      campaign: undefined,
      category: undefined,
      color: undefined,
      gender: undefined,
      model: undefined,
      price: undefined,
      size: undefined,
    },
    page: {
      current: 0,
      size: DEFAULT_NUMBER_PRODUCTS_PER_PAGE,
    },
    q: "",
    sort: {
      by: SORTING_OPTIONS_RELEVANCE,
      direction: undefined,
    },
  },
  request_cache: true,
  wpCategory: undefined,
};

const slice = createSlice({
  name: "payload",
  initialState,
  reducers: {
    updateFilters: (state, { payload }) => {
      if (payload.filter === "price") {
        if (payload.value === undefined) {
          return void (state.payload.filters["price"] = undefined);
        }

        return void (state.payload.filters["price"] = !isNaN(payload.value[0])
          ? [...payload.value]
          : undefined);
      }

      if (payload.filter === "category") {
        return void (state.payload.filters["category"] = [...payload.value]);
      }

      if (!state.payload.filters[payload.filter]) {
        state.payload.filters[payload.filter] = [];
      }

      state.payload.filters[payload.filter].push(...payload.value);
    },
    removeFilters: (state, { payload }) => {
      state.payload.filters[payload.filter] = state.payload.filters[
        payload.filter
      ].filter((f) => f !== payload.value[0]);

      if (state.payload.filters[payload.filter].length === 0) {
        state.payload.filters[payload.filter] = undefined;
      }
    },
    toggleFilters: (state, { payload }) => {
      if (!state.payload.filters[payload.filter]) {
        state.payload.filters[payload.filter] = [];
      }

      if (state.payload.filters[payload.filter].includes(payload.value[0])) {
        slice.caseReducers.removeFilters(state, { payload });
      } else {
        slice.caseReducers.updateFilters(state, { payload });
      }
    },
    updatePages: (state, { payload }) => {
      state.payload.page[payload.page] = payload.value;
    },
    updatePageSize: (state, { payload }) => {
      state.payload.page[payload.page] = payload.value;
      state.payload.page.current = 0;
    },
    updateFeatures: (state, { payload }) => {},
    updateQuery: (state, { payload }) => {
      state.payload.q = payload.value;
    },
    updateSort: (state, { payload }) => {
      let sortBy = payload.sort;
      let sortDirection = undefined;

      if (SORTING_OPTIONS_PRICE_ASC === payload.sort) {
        sortBy = "price";
        sortDirection = "asc";
      } else if (SORTING_OPTIONS_PRICE_DESC === payload.sort) {
        sortBy = "price";
        sortDirection = "desc";
      } else if (SORTING_OPTIONS_POPULAR_ASC === payload.sort) {
        sortBy = "popular";
        sortDirection = "asc";
      } else if (SORTING_OPTIONS_POPULAR_DESC === payload.sort) {
        sortBy = "popular";
        sortDirection = "desc";
      }

      state.payload.sort = {
        by: sortBy,
        direction: sortDirection,
      };
    },
    updateWpCategory: (state, { payload }) => {
      state.wpCategory = payload.wpCategory;

      if (payload.category === undefined) {
        return state.payload.filters.category = undefined;
      }
      
      state.payload.filters.category = [...payload.category];
    },
    reset: () => initialState,
  },
});

const searchPayloadReducer = slice.reducer;

export const {
  updateWpCategory,
  updateSort,
  updateQuery,
  updatePages,
  updatePageSize,
  reset,
  updateFilters,
  removeFilters,
  toggleFilters,
} = slice.actions;
export { searchPayloadReducer };

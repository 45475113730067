import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { updatePages } from "../reducers/searchPayloadReducer";

import { ReactComponent as LeftChevronIcon } from "../images/icons/chevron_left.svg";
import { ReactComponent as RightChevronIcon } from "../images/icons/chevron_right.svg";

import styles from "./Pagination.module.scss";

const Pagination = React.memo(
  ({ classList = { root: "" }, currentPage, totalPages }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const links = [];
    const showLeftChevron = currentPage > 1;
    const showRightChevron = currentPage < totalPages;
    const updatePage = (pageNumber) =>
      dispatch(updatePages({ page: "current", value: pageNumber }));

    if (totalPages <= 1) {
      return null;
    }

    if (totalPages <= 7) {
      for (let pageNumber = 1; pageNumber <= totalPages; pageNumber += 1) {
        links.push(
          <li
            className={classnames(styles.pageNumbers, {
              [styles.pageNumberSelected]: pageNumber === currentPage,
            })}
            key={`pagination_${pageNumber}`}
          >
            {pageNumber === currentPage ? (
              pageNumber
            ) : (
              <a onClick={() => updatePage(pageNumber)}>{pageNumber}</a>
            )}
          </li>
        );
      }
    } else if (currentPage === 1) {
      for (let pageNumber = 1; pageNumber < 4; pageNumber += 1) {
        links.push(
          <li
            className={classnames(styles.pageNumbers, {
              [styles.pageNumberSelected]: pageNumber === currentPage,
            })}
            key={`pagination_${pageNumber}`}
          >
            {pageNumber === currentPage ? (
              pageNumber
            ) : (
              <a onClick={() => updatePage(pageNumber)}>{pageNumber}</a>
            )}
          </li>
        );
      }

      links.push(<li key="pagination_ellipsis_1">…</li>);

      links.push(
        <li
          className={classnames(styles.pageNumbers, {
            [styles.pageNumberSelected]: totalPages === currentPage,
          })}
          key={`pagination_${totalPages}`}
        >
          {totalPages === currentPage ? (
            totalPages
          ) : (
            <a onClick={() => updatePage(totalPages)}>{totalPages}</a>
          )}
        </li>
      );
    } else if (currentPage < 4) {
      for (let pageNumber = 1; pageNumber <= currentPage + 2; pageNumber += 1) {
        links.push(
          <li
            className={classnames(styles.pageNumbers, {
              [styles.pageNumberSelected]: pageNumber === currentPage,
            })}
            key={`pagination_${pageNumber}`}
          >
            {pageNumber === currentPage ? (
              pageNumber
            ) : (
              <a onClick={() => updatePage(pageNumber)}>{pageNumber}</a>
            )}
          </li>
        );
      }

      links.push(<li key="pagination_ellipsis_1">…</li>);

      links.push(
        <li
          className={classnames(styles.pageNumbers, {
            [styles.pageNumberSelected]: totalPages === currentPage,
          })}
          key={`pagination_${totalPages}`}
        >
          {totalPages === currentPage ? (
            totalPages
          ) : (
            <a onClick={() => updatePage(totalPages)}>{totalPages}</a>
          )}
        </li>
      );
    } else if (currentPage > totalPages - 3) {
      links.push(
        <li
          className={classnames(styles.pageNumbers, {
            [styles.pageNumberSelected]: 1 === currentPage,
          })}
          key={`pagination_${1}`}
        >
          {1 === currentPage ? 1 : <a onClick={() => updatePage(1)}>{1}</a>}
        </li>
      );

      links.push(<li key="pagination_ellipsis_1">…</li>);

      for (
        let pageNumber = totalPages - 4;
        pageNumber <= totalPages;
        pageNumber += 1
      ) {
        links.push(
          <li
            className={classnames(styles.pageNumbers, {
              [styles.pageNumberSelected]: pageNumber === currentPage,
            })}
            key={`pagination_${pageNumber}`}
          >
            {pageNumber === currentPage ? (
              pageNumber
            ) : (
              <a onClick={() => updatePage(pageNumber)}>{pageNumber}</a>
            )}
          </li>
        );
      }
    } else {
      links.push(
        <li
          className={classnames(styles.pageNumbers, {
            [styles.pageNumberSelected]: currentPage === 1,
          })}
          key="pagination_1"
        >
          {currentPage === 1 ? 1 : <a onClick={() => updatePage(1)}>1</a>}
        </li>
      );

      if (currentPage !== 4) links.push(<li key="pagination_ellipsis_1">…</li>);

      for (
        let pageNumber = currentPage - 2;
        pageNumber <= currentPage + 2;
        pageNumber += 1
      ) {
        links.push(
          <li
            className={classnames(styles.pageNumbers, {
              [styles.pageNumberSelected]: pageNumber === currentPage,
            })}
            key={`pagination_${pageNumber}`}
          >
            {pageNumber === currentPage ? (
              pageNumber
            ) : (
              <a onClick={() => updatePage(pageNumber)}>{pageNumber}</a>
            )}
          </li>
        );
      }

      if (currentPage !== totalPages - 3)
        links.push(<li key="pagination_ellipsis_2">…</li>);

      links.push(
        <li
          className={classnames(styles.pageNumbers, {
            [styles.pageNumberSelected]: currentPage === totalPages,
          })}
          key={`pagination_${totalPages}`}
        >
          {currentPage === totalPages ? (
            totalPages
          ) : (
            <a onClick={() => updatePage(totalPages)}>{totalPages}</a>
          )}
        </li>
      );
    }

    return (
      <nav className={classnames(styles.root, classList.root)}>
        <ul>
          {showLeftChevron && (
            <li className={styles.paginationArrows}>
              <a
                aria-label={intl.messages["common.previous"]}
                onClick={() => updatePage(currentPage - 1)}
              >
                <LeftChevronIcon />
              </a>
            </li>
          )}
          {links}
          {showRightChevron && (
            <li className={styles.paginationArrows}>
              <a
                aria-label={intl.messages["registrationForm.buttonNext"]}
                onClick={() => updatePage(currentPage + 1)}
              >
                <RightChevronIcon />
              </a>
            </li>
          )}
        </ul>
      </nav>
    );
  }
);

Pagination.displayName = "Pagination";
Pagination.propTypes = {
  classList: PropTypes.objectOf(PropTypes.string),
  currentPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  totalPages: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Pagination;

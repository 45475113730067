import PropTypes from "prop-types";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import Checkbox from "../Checkbox/Checkbox";

import { ReactComponent as ArrowRightIcon } from "../images/icons/arrowRight.svg";

import styles from "./FilterSearchable.module.scss";
import FilterShowAllPopup from "../FilterShowAllPopup/FilterShowAllPopup";

const FilterSearchable = ({
  classList = {},
  onChange,
  hasMore = false,
  selected = [],
  items,
  placeholder,
  title,
  searchPayload,
  type,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [showingMore, setShowingMore] = useState(false);
  const sortedList = [...items].sort((listItem) => {
    return selected.includes(listItem.key) ? -1 : 0;
  });
  const shouldRenderShowMoreButton = items.length > 10 || hasMore;
  return (
    <>
      <ul className={styles.root}>
        {sortedList.slice(0, 10).map((item) => (
          <li key={item.key}>
            <Checkbox
              checked={selected.includes(item.key)}
              classList={{
                root: classList.checkboxLabel,
                checkboxButton: classList.checkboxButton,
              }}
              onChange={(e) =>
                onChange({
                  hasBeenRemoved: !e.currentTarget.checked,
                  value: e.currentTarget.value,
                })
              }
              value={item.key}
            >
              <span className={styles.itemName}>{item.key}</span>
              <span className={styles.itemDocs}>({item.doc_count})</span>
            </Checkbox>
          </li>
        ))}
        {shouldRenderShowMoreButton && (
          <li className={styles.toggleCategoriesBtnContainer}>
            <button
              className={styles.toggleCategoriesBtn}
              onClick={() => setShowingMore((currentValue) => !currentValue)}
            >
              <>
                <a>
                  <FormattedMessage
                    defaultMessage="Show all"
                    id="common.showAll"
                  />
                  <ArrowRightIcon className={styles.toggleDirectionIcon} />
                </a>
              </>
            </button>
          </li>
        )}
      </ul>
      {showingMore && (
        <FilterShowAllPopup
          placeholder={placeholder}
          items={items}
          title={title}
          searchPayload={searchPayload}
          classList={{
            checkboxLabel: styles.filtersCheckboxLabel,
            checkboxButton: styles.filtersCheckboxButton,
          }}
          selected={selected}
          setShowingMore={setShowingMore}
          filterType={type}
        />
      )}
    </>
  );
};

FilterSearchable.displayName = "FilterSearchable";
FilterSearchable.propTypes = {
  onChange: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.array.isRequired,
};

const mapStateToProps = ({ intl }) => ({ intl });

export default connect(mapStateToProps)(FilterSearchable);

import useFetch from './useFetch';

/**
 * @typedef UseContentApiResponse
 * @property {mixed} data Data obtained from the request. Initialized as `null`
 * @property {Error} error Error caught while making the request.
 * @property {boolean} loading Flag determining if the request is still happening or not. Initializes as true.
 */

/**
 * Makes a request to our Content API.
 *
 * @function useContentApi
 * @param {string} path
 * @param {object} [options]
 * @returns {UseContentApiResponse}
 */
const useContentApi = (path, options) => useFetch(`${process.env.REACT_APP_CONTENT_API_URL}${path}`, options);

export default useContentApi;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import styles from './ProductDetailsCard.module.scss';

import Price from './Price';
import Ribbon from './Ribbon';

import { ReactComponent as ArrowDown } from '../images/icons/arrowDown.svg';
import { ReactComponent as ArrowUp } from '../images/icons/arrowUp.svg';

export const Shops = ({ isTablet, product, shopsList }) => {
  const [allShopsShown, setAllShopsShown] = useState(false);

  const { currency } = product;
  const shopsToDisplayMax = 3;
  const shopsToDisplayCount =
    allShopsShown || shopsList.length < shopsToDisplayMax ? shopsList.length : shopsToDisplayMax;
  const showAllLip = product.shops ? (
    <FormattedMessage id="shop.showAll" defaultMessage="Show all" values={{ count: shopsList.length }} />
  ) : (
    ''
  );

  const showAllShopsButtonHandler = () => {
    setAllShopsShown(!allShopsShown);
  };

  const shopsTableMargin = 30;
  const tHeadHeight = 37;
  const heightOffset = shopsTableMargin + tHeadHeight;
  if (isTablet) {
    return (
      <div className={styles.shops} style={{ height: `${heightOffset + 45 * shopsToDisplayCount}px` }}>
        <table className={styles.shopsTable} cellSpacing="0">
          <thead>
            <tr>
              <th className={styles.shopNameHeading}>
                <FormattedMessage defaultMessage="Shop" id="shop.sortBy.name" />
              </th>
              <th className={styles.deliveryTimeHeading}>
                <FormattedMessage defaultMessage="Delivery Time" id="shop.sortBy.deliveryTime" />
              </th>
              <th className={styles.deliveryPriceHeading}>
                <FormattedMessage defaultMessage="Delivery Costs" id="shop.sortBy.deliveryPrice" />
              </th>
              <th className={styles.priceHeading}>
                <FormattedMessage defaultMessage="Price" id="shop.sortBy.price" />
              </th>
            </tr>
          </thead>
          <tbody>
            {shopsList.map(({ deliveryCosts, deliveryTime, id, campaign: name, price, offers }) => (
              <tr key={id}>
                <td className={styles.shopNameColValue}>
                  <a href={offers[0].url} rel="noopener noreferrer" target="_blank">
                    <span>{name}</span>
                  </a>
                  <Ribbon />
                </td>
                <td className={styles.deliveryTimeColValue}>
                  <a href={offers[0].url} rel="noopener noreferrer" target="_blank">
                    <span>{deliveryTime}</span>
                  </a>
                </td>
                <td className={styles.deliveryPriceColValue}>
                  <a href={offers[0].url} rel="noopener noreferrer" target="_blank">
                    {parseInt(deliveryCosts, 10) === 0 ? (
                      <span className={styles.priceColValueGratis}>
                        <FormattedMessage defaultMessage="gratis" id="product.freeDelivery" />
                      </span>
                    ) : (
                      <Price
                        classList={{ currency: styles.shopTableCurrency, price: styles.shopTablePrice }}
                        currency={currency}
                        price={deliveryCosts}
                      />
                    )}
                  </a>
                </td>
                <td className={styles.priceColValue}>
                  <a href={offers[0].url} rel="noopener noreferrer" target="_blank">
                    <Price
                      classList={{ currency: styles.shopTableCurrency, price: styles.shopTablePrice }}
                      currency={currency}
                      price={price}
                    />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {shopsList.length > shopsToDisplayMax && isTablet && (
          <div onClick={showAllShopsButtonHandler} className={styles.showMoreShops}>
            {!allShopsShown ? showAllLip : <FormattedMessage defaultMessage="Show less" id="common.showLess" />}
            {!allShopsShown ? (
              <ArrowDown size={10} className={styles.showMoreShopsButtonArrow} />
            ) : (
              <ArrowUp size={10} className={styles.showMoreShopsButtonArrow} />
            )}
          </div>
        )}
      </div>
    );
  }

  if (!isTablet) {
    return (
      <div className={styles.shops}>
        <table className={styles.shopsTable} cellSpacing="0">
          <thead>
            <tr>
              <th className={styles.shopNameHeading}>
                <FormattedMessage defaultMessage="Shop" id="shop.sortBy.name" />
              </th>
              <th className={styles.priceHeading}>
                <FormattedMessage defaultMessage="Price" id="shop.sortBy.price" />
              </th>
            </tr>
          </thead>
          <tbody>
            {shopsList.map(({ id, campaign: name, price, offers }) => (
              <tr key={id}>
                <td className={styles.shopNameColValue}>
                  <Ribbon />
                  <a href={offers[0].url} rel="noopener noreferrer" target="_blank">
                    <span>{name}</span>
                  </a>
                </td>
                <td className={styles.priceColValue}>
                  <a href={offers[0].url} rel="noopener noreferrer" target="_blank">
                    <Price
                      classList={{ currency: styles.shopTableCurrency, price: styles.shopTablePrice }}
                      currency={currency}
                      price={price}
                    />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
};
Shops.propTypes = {
  isTablet: PropTypes.bool.isRequired,
  product: PropTypes.object.isRequired,
  shopsList: PropTypes.array.isRequired,
};

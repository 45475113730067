import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumberParts } from 'react-intl';
import classnames from 'classnames';

import styles from './ProductDetailsCard.module.scss';

const Price = ({ classList = {}, currency, originalPrice = false, price }) => (
  <FormattedNumberParts currency={currency} style="currency" value={price}>
    {(parts) => {
      const currencyPart = parts.find((part) => part.type === 'currency');
      const integerValue = parts.reduce(
        (output, part) => (part.type === 'integer' ? `${output}${part.value}` : output),
        ''
      );
      const decimalPart = parts.find((part) => part.type === 'decimal');
      const fractionPart = parts.find((part) => part.type === 'fraction');

      return (
        <>
          <span
            className={classnames(styles.priceCurrency, classList.currency, {
              [styles.originalCurrency]: originalPrice,
            })}
          >
            {currencyPart.value}
          </span>
          <span className={classnames(styles.priceValue, classList.price, { [styles.originalPrice]: originalPrice })}>
            <>
              {integerValue}
              {decimalPart.value}
              {fractionPart.value > 0 ? fractionPart.value : '-'}
            </>
          </span>
        </>
      );
    }}
  </FormattedNumberParts>
);

Price.propTypes = {
  currency: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
};

export default Price;

import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { IntlProvider } from "react-intl";
import flattenMessages from "./helpers/flattenMessages";
import { Provider } from "react-redux";

import { store } from "./store";

import en from "./locales/en.json";
import nl from "./locales/nl.json";

const messages = {
  nl: nl,
  en: en,
};
const language = navigator.language.split(/[-_]/)[0]; // language without region code

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <IntlProvider
        locale={language}
        key={language}
        messages={flattenMessages(messages[language])}
        defaultLocale="nl"
      >
        <App />
      </IntlProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("siab-widget")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

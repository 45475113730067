import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { useDispatch } from "react-redux";
import parse from 'html-react-parser';
import { updatePageSize } from "../reducers/searchPayloadReducer";

import { ReactComponent as ChevronDownIcon } from "../images/icons/chevron_down.svg";

import styles from "./PageSizeDropdown.module.scss";

const PAGE_SIZE_ALLOWED_VALUES = [30, 60, 120, 180];

const PageSizeDropdown = ({ selected }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fn = (e) => setOpen(false);
    window.addEventListener("click", fn);

    return () => window.removeEventListener("click", fn);
  });

  return (
    <div
      className={classnames(styles.root, { [styles.rootOpen]: open })}
      onClick={(e) => e.stopPropagation()}
    >
      <button
        className={styles.trigger}
        onClick={() => setOpen((prevValue) => !prevValue)}
      >
       <FormattedHTMLMessage
          defaultMessage="<span class='pageSizeValuePart'>{pageSize}</span> producten per pagina"
          id="pagination.pageSize"
          values={{ pageSize: selected }}
        />
        <ChevronDownIcon />
      </button>
      <ul className={styles.values}>
        {PAGE_SIZE_ALLOWED_VALUES.map((item) => {
          const isSelected = selected === item;
          return (
            <li
              className={classnames(styles.value, {
                [styles.valueSelected]: isSelected,
              })}
              key={`page_size_${item}`}
              onClick={() => setOpen(false)}
            >
              <a
                onClick={() =>
                  dispatch(updatePageSize({ page: "size", value: item }))
                }
              >
                <FormattedHTMLMessage
                  defaultMessage="<span class='valuePart'>{pageSize}</span> producten per pagina"
                  id="pagination.pageSize"
                  values={{ pageSize: item }}
                >{(text) => parse(text)}

                </FormattedHTMLMessage>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PageSizeDropdown;

import React from 'react';
import styles from './ProductDetailsCard.module.scss';

const Ribbon = () => (
  <div className={styles.ribbon}>
    <svg
      width="22px"
      height="45px"
      viewBox="0 0 66 139"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.arrow}
    >
      <g>
        <path d="M0,0 L12.6798601,4.12296077e-16 C15.7287266,-1.47771612e-16 16.8141902,0.160370078 17.9969055,0.518628748 C19.1796209,0.876887418 20.2107009,1.43303468 21.1597038,2.22458905 C22.1087068,3.01614342 22.8389275,3.83512296 24.5135649,6.38290166 L61.0656742,61.99296 C62.9638736,64.880864 63.4545647,66.0514064 63.7773877,67.4353192 C64.1002107,68.819232 64.1002107,70.180768 63.7773877,71.5646808 C63.4545647,72.9485936 62.9638736,74.119136 61.0656742,77.00704 L24.5135649,132.617098 C22.8389275,135.164877 22.1087068,135.983857 21.1597038,136.775411 C20.2107009,137.566965 19.1796209,138.123113 17.9969055,138.481371 C16.8141902,138.83963 15.7287266,139 12.6798601,139 L0,139 L0,0 Z"></path>
      </g>
    </svg>
  </div>
);

export default Ribbon;

import sorter from 'apparel-sorter';

function sortNumbers(arr) {
  return arr.sort((a, b) => (parseInt(a, 10) < parseInt(b, 10) ? -1 : 1));
}

export default function getSortedSizes(arrToBeSorted) {
  const parts = arrToBeSorted.reduce(
    (output, size) => {
      if (/^(\d*?)x*(s|m|l)$/i.test(size)) {
        output.literal.push(size.key);
      } else if (/^[\d.,-]+$/.test(size.key) && !isNaN(parseInt(size.key, 10))) {
        output.numeric.push(size.key);
      } else {
        output.others.push(size.key);
      }

      return output;
    },
    { literal: [], numeric: [], others: [] }
  );

  parts.numeric = sortNumbers(parts.numeric).map((item) => ({ key: item }));
  parts.literal = sorter.sort(parts.literal).map((item) => ({ key: item }));
  parts.others = sorter.sort(parts.others).map((item) => ({ key: item }));

  return [...parts.numeric, ...parts.literal, ...parts.others];
}
